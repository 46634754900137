import React from "react";
import { Order } from "../../models/order";
import { Box } from "../base/box/Box";
import { FluidGrid } from "../base/styles/fluid-grid";
import { OrderGridItem } from "./order-grid-item";

interface Props {
  orders: Order[];
}

export function OrderGrid({ orders }: Props) {
  if (orders.length < 1) {
    return (
      <Box data-cy={`noContent`} textAlign="center" paddingTop="1rem">
        No orders at the moment, but we still think you're awesome{" "}
        <span aria-label="hands raised" role="img">
          🙌
        </span>
      </Box>
    );
  }

  return (
    <FluidGrid className="order-grid">
      {orders.map((order) => (
        <OrderGridItem key={order.id} order={order} />
      ))}
    </FluidGrid>
  );
}
