import * as React from "react";

export interface IconProps {
  alt: string;
  imageSrc: string;
  width?: string | number;
  height?: string | number;
}

export function Icon({
  alt,
  imageSrc,
  width,
  height = "100%",
  ...props
}: IconProps) {
  if (imageSrc != null && imageSrc !== "") {
    return (
      <img
        className="icon"
        width={width}
        height={height}
        src={imageSrc}
        {...props}
        alt={alt}
      />
    );
  }
  return <></>;
}
