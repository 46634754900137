import React from "react";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { SET_TABLE_COUNT } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { IncrementInput } from "../increment-input/IncrementInput";

export function TableCountSetting() {
  const dispatch = useRadarDispatch();
  const {
    settings: { customerViewTableCount = 1 },
  } = useRadarState();

  function onDecrement() {
    if (customerViewTableCount > 1) {
      dispatch({
        type: SET_TABLE_COUNT,
        numberOfTables: 1,
      });
      logAmplitudeEvent(LogEvent.UpdateTableCount, {
        TYPE: "DECREMENT",
        NUMBER_OF_COLUMNS: 1,
      });
    }
  }
  function onIncrement() {
    if (customerViewTableCount < 2) {
      dispatch({
        type: SET_TABLE_COUNT,
        numberOfTables: 2,
      });
      logAmplitudeEvent(LogEvent.UpdateTableCount, {
        TYPE: "INCREMENT",
        NUMBER_OF_COLUMNS: 2,
      });
    }
  }

  return (
    <IncrementInput
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      text={customerViewTableCount}
    />
  );
}
