import gql from "graphql-tag";

const fragments = {
  order: gql`
    fragment OrderTableOrders on Order {
      id
      locationNumber
      status
      # drinkCount
      # treatCount
      # entreeCount
      user {
        firstName
        lastName
      }
      fulfillment {
        destination
        zones {
          kpsFulfillmentDetails
          dineIn
          radius
        }
        vehicle {
          id
          make
          model
          color
        }
      }
      origin {
        id
      }
      timestamps {
        submit
        checkIn
        ready
        error
        complete
      }
    }
  `,
};

export const ORDERS_QUERY = gql`
  query getOrders($locationNumber: String!) {
    orders(locationNumber: $locationNumber) {
      ...OrderTableOrders
    }
  }
  ${fragments.order}
`;

export const ORDERS_SUBSCRIPTION = gql`
  subscription onOrderUpdated($locationNumber: String!) {
    orderUpdated(locationNumber: $locationNumber) {
      ...OrderTableOrders
    }
  }
  ${fragments.order}
`;

export const ORDER_COMPLETE_MUTATION = gql`
  mutation completeOrder($orderID: String!) {
    completeOrder(orderID: $orderID) {
      ...OrderTableOrders
    }
  }
  ${fragments.order}
`;
