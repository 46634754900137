import React from "react";
import styled from "styled-components";
import { ReactComponent as PinRed } from "../../icons/pin-red.svg";
import { ReactComponent as Pin } from "../../icons/pin.svg";

interface Props {
  checked: boolean;
  onChange: () => void;
}

export function PinCheckbox({ checked, onChange }: Props) {
  return (
    <StyledButton
      className={checked ? "checked" : ""}
      onClick={onChange}
      onTouchEnd={(e) => {
        onChange();
        e.preventDefault();
      }}
    >
      {checked ? <PinRed /> : <Pin />}
    </StyledButton>
  );
}

const StyledButton = styled("button")`
  background: none;
  border: none;
`;
