import amplitude from "amplitude-js";
import { isProd } from "../lib/config/config";

import { LogEvent } from "./types";

export const initAmplitude = () => {
  if (!isProd) {
    return;
  }

  amplitude.getInstance().init("dc1708b35bbb3039b3810990f9a06016");
};

export const setAmplitudeUser = (userId: string | null, properties = {}) => {
  if (!isProd) {
    return;
  }

  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().setUserProperties(properties);
};

export const logAmplitudeEvent = (
  eventType: LogEvent,
  eventProperties = {}
) => {
  if (!isProd) {
    return;
  }

  amplitude.getInstance().logEvent(eventType, eventProperties);
};
