import styled from "styled-components";

export const Footer = styled.footer`
  background-color: ${(props) => props.theme?.colors?.secondary};

  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;
