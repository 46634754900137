import * as React from "react";
import styled from "styled-components";
import { DeliveryPartner } from "../../../models/delivery-partners";
import { Icon } from "../../base/icon/icon";

export interface DeliveryIconProps {
  id: DeliveryPartner;
}

export function DeliveryIcon({ id, ...props }: DeliveryIconProps) {
  let image = "";
  switch (id) {
    case DeliveryPartner.UBER_EATS:
      image = "/icons/UberEats.svg";
      break;
    case DeliveryPartner.DOOR_DASH:
      image = "/icons/DoorDash.svg";
      break;
    case DeliveryPartner.POSTMATES:
      image = "/icons/Postmates.svg";
      break;
    case DeliveryPartner.GRUB_HUB:
      image = "/icons/GrubHub.svg";
      break;
    default:
      return <></>;
  }

  return (
    <PositionedIcon>
      <Icon alt={id} imageSrc={image} {...props} />
    </PositionedIcon>
  );
}

const PositionedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
`;
