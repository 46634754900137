import React from "react";
import { useTheme } from "styled-components";
import { DeliveryPartner } from "../../models/delivery-partners";
import { Order, OrderFulfillmentDestination } from "../../models/order";
import { ViewType } from "../../models/view";
import { Box } from "../base/box/Box";
import { useDelayedStatus } from "../hooks/useDelayedStatus";
import { DeliveryIcon } from "../orders-table/cell-types/delivery-icon";

interface Props {
  order: Order;
}

export function OrderGridItem({ order }: Props) {
  const theme = useTheme();
  const { delayedStatus } = useDelayedStatus({
    viewType: ViewType.CUSTOMER,
    status: order.status,
    timestamps: order.timestamps,
  });

  if (!delayedStatus) {
    return null;
  }

  return (
    <Box
      className="order-grid-item"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={theme.colors.statuses[delayedStatus]}
      width="100%"
      paddingX="1rem"
      paddingY="0.75rem"
      textAlign="center"
      borderRadius={5}
    >
      <Box
        overflow="hidden"
        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      >{`${order.user.firstName} ${order.user.lastName}.`}</Box>

      <DeliveryIcon
        id={
          order?.fulfillment?.destination ===
          OrderFulfillmentDestination.DOOR_DASH
            ? DeliveryPartner.DOOR_DASH
            : (order?.origin?.id as DeliveryPartner)
        }
      />
    </Box>
  );
}
