import React from "react";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { OrderStatus } from "../../models/order";
import { SET_AUTO_CLEAR_TIMEOUTS } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { IncrementInput } from "../increment-input/IncrementInput";

export function ClearBumpedSetting() {
  const dispatch = useRadarDispatch();
  const {
    settings: {
      autoClearTimeouts: { Ready = 5 },
    },
  } = useRadarState();

  function onDecrement() {
    if (Ready > 1) {
      dispatch({
        type: SET_AUTO_CLEAR_TIMEOUTS,
        status: OrderStatus.Ready,
        timeout: Ready - 1,
      });
      logAmplitudeEvent(LogEvent.UpdateBumpTimeout, {
        TYPE: "DECREMENT",
        TIMEOUT: Ready - 1,
      });
    }
  }
  function onIncrement() {
    if (Ready < 20) {
      dispatch({
        type: SET_AUTO_CLEAR_TIMEOUTS,
        status: OrderStatus.Ready,
        timeout: Ready + 1,
      });
      logAmplitudeEvent(LogEvent.UpdateBumpTimeout, {
        TYPE: "INCREMENT",
        TIMEOUT: Ready + 1,
      });
    }
  }

  function pluralizeMinutes(numMinutes: number) {
    return numMinutes === 1 ? "min" : "mins";
  }
  const text = `${Ready}:00 ${pluralizeMinutes(Ready)}`;

  return (
    <IncrementInput
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      text={text}
    />
  );
}
