import { SecureRoute, useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { setAmplitudeUser } from "../../analytics/amplitude";
import { getEnvConfig } from "../../lib/config/config";
import { User } from "../../models/user";
import { ViewType } from "../../models/view";
import { SET_USER } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import {
  CUSTOMER_ORDERS_PATH,
  ORDERS_PATH,
  RESTAURANT_ORDERS_PATH,
} from "../page-routing-config";
import { CustomerView } from "./customer/customer-view";
import { RestaurantView } from "./restaurant/restaurant-view";

export function OrdersViewRouter() {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useRadarDispatch();
  const { viewType } = useRadarState();
  const history = useHistory();
  const {
    Okta: { logoutUri },
  } = getEnvConfig();

  useEffect(() => {
    async function getUser() {
      const user = await oktaAuth.getUser<User>();

      if (!oktaAuth.isAuthenticated || !user) {
        await oktaAuth.signInWithRedirect();
        return;
      }

      dispatch({ type: SET_USER, user });

      if (user && user["cfa-guid"]) {
        setAmplitudeUser(user["cfa-guid"], {
          NAME: user.name,
          EMAIL: user.email,
          TYPE: user.userType,
        });
      }
    }

    getUser();
  }, [oktaAuth, dispatch, logoutUri]);

  useEffect(() => {
    if (viewType === ViewType.CUSTOMER) {
      history.replace(CUSTOMER_ORDERS_PATH);
    }
  }, [viewType]);

  return (
    <Switch>
      <SecureRoute path={RESTAURANT_ORDERS_PATH}>
        <RestaurantView />
      </SecureRoute>
      <SecureRoute path={CUSTOMER_ORDERS_PATH}>
        <CustomerView />
      </SecureRoute>
      <SecureRoute path={ORDERS_PATH}>
        <Redirect to={RESTAURANT_ORDERS_PATH} />
      </SecureRoute>
    </Switch>
  );
}
