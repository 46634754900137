import React from "react";
import { useTheme } from "styled-components";
import { Box } from "../base/box/Box";

interface LegendItem {
  color: string;
  text: string;
}

interface Props {
  items: LegendItem[];
}

export function OrderGridLegend({ items }: Props) {
  const theme = useTheme();

  return (
    <Box
      className="order-grid-legend"
      display="flex"
      justifyContent="center"
      marginBottom="1.5rem"
    >
      {items.map(({ color, text }) => (
        <Box key={text} display="flex" alignItems="center" marginX="0.75rem">
          <Box
            className="order-grid-legend-dot"
            border={`1px solid ${theme.colors.outline}`}
            backgroundColor={color}
            width="1.5rem"
            height="1.5rem"
            borderRadius="50%"
            marginRight="0.5rem"
          />
          <span className="order-grid-legend-text">{text}</span>
        </Box>
      ))}
    </Box>
  );
}
