import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { initAmplitude } from "./analytics/amplitude";
import App from "./App";
import { ENVS, getEnv } from "./lib/config/config";

// Initialize Amplitude analytics
initAmplitude();

// Setup bugsnag
Bugsnag.start({
  apiKey: "edce271280134478c3d90058b51280a7",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ENVS.QA, ENVS.PROD],
  releaseStage: getEnv(),
});

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  ErrorBoundary ? (
    <ErrorBoundary>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </ErrorBoundary>
  ) : null
);
