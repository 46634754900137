import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { FilterCategory } from "../../models/filter";
import { ViewType } from "../../models/view";
import { SET_FILTERS } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { mapFilterCategoryToDisplayName } from "../../utils/mappers/filterMapper";
import ToggleButton from "../base/toggle-button/toggle-button";

export interface FilterGroupProps {
  viewType: ViewType;
  category: FilterCategory;
  options: FilterOption[];
}

export interface FilterOption {
  id: string;
  name: string;
}

const FilterGroupDisplay = styled.div`
  color: ${(props) => props.theme?.colors?.text};
  font-weight: bold;
  font-size: 0.875rem;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const StyledToggleButton = styled.div`
  width: 47%;
`;

export const FilterGroup: FunctionComponent<FilterGroupProps> = ({
  category,
  options,
  viewType,
}) => {
  const dispatch = useRadarDispatch();
  const { filters } = useRadarState();
  const filterConfig = filters[viewType] || {};

  const currentFiltersForCategory = filterConfig[category] || [];

  const onToggle = (option: FilterOption) => {
    let newActiveOptions;

    const isToggleActive = currentFiltersForCategory.indexOf(option.id) !== -1;

    if (isToggleActive) {
      newActiveOptions = currentFiltersForCategory.filter(
        (filter) => filter !== option.id
      );
    } else {
      newActiveOptions = [...currentFiltersForCategory, option.id];
    }

    dispatch({
      type: SET_FILTERS,
      filterCategory: category,
      fields: newActiveOptions,
      viewType,
    });
    logAmplitudeEvent(LogEvent.UpdateFilter, {
      TYPE: option.name,
      CATEGORY: category,
      VIEW_TYPE: viewType,
      ACTIVE: !isToggleActive,
    });
  };

  return (
    <FilterGroupDisplay>
      <p data-cy={`filter-header-${category}`}>
        <span>{mapFilterCategoryToDisplayName(category)}</span>
      </p>
      <ButtonGroupContainer data-cy={`filter-group-${category}`}>
        {options.map((opt) => {
          return (
            <StyledToggleButton key={opt.name}>
              <ToggleButton
                key={opt.name}
                data-cy={`filter-button-${opt.name}`}
                active={currentFiltersForCategory.indexOf(opt.id) !== -1}
                onToggle={() => {
                  onToggle(opt);
                }}
              >
                {opt.name}
              </ToggleButton>
            </StyledToggleButton>
          );
        })}
      </ButtonGroupContainer>
    </FilterGroupDisplay>
  );
};
