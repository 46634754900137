import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import {
  OrderFulfillment,
  OrderFulfillmentDestination,
} from "../../../models/order";
import { Box } from "../../base/box/Box";

export interface LocatorProps {
  fulfillment: OrderFulfillment;
}

interface CarColorMap {
  [key: string]: {
    color: string;
    borderColor?: string;
  };
}

export function Locator({ fulfillment, ...props }: LocatorProps) {
  const {
    zones,
    vehicle: { color, make, model },
  } = fulfillment;

  const formattedColor = color.replace(/other|pending/gi, "");
  const formattedMake = make.replace(/other|pending/gi, "");
  const formattedModel = model.replace(/other|pending/gi, "");
  const fulfillmentDetails = zones?.kpsFulfillmentDetails || zones?.dineIn;

  const zoneArr: string[] = [];

  if (zones?.radius?.trim()) {
    zoneArr.push(`Zone ${zones.radius}`);
  }
  if (fulfillmentDetails?.trim()) {
    if (!Number(fulfillmentDetails)) {
      zoneArr.push(fulfillmentDetails);
    } else {
      if (fulfillment.destination === OrderFulfillmentDestination.DINE_IN) {
        zoneArr.push(`Table ${fulfillmentDetails}`);
      } else {
        zoneArr.push(`Space ${fulfillmentDetails}`);
      }
    }
  }

  return (
    <StyledLocatorCell {...props}>
      {formattedColor.length > 0 && (
        <Box mr="1rem">
          <StyledVehicleColorDot
            className="vehicleColorDot"
            data-cy="vehicleColor"
            data-testid="vehicle-color-dot"
            vehicleColor={formattedColor}
          />
        </Box>
      )}
      <div
        style={{
          width: "100%",
          flexDirection: "column",
          marginRight: "1rem",
        }}
      >
        {(formattedColor || formattedMake || formattedModel) && (
          <div data-cy="vehicleDescriptor">
            {`${formattedColor} ${formattedMake} ${formattedModel}`}
          </div>
        )}
        {zoneArr.length > 0 && (
          <StyledZone data-cy="zone">{zoneArr.join(", ")}</StyledZone>
        )}
      </div>
    </StyledLocatorCell>
  );
}

function mapColorToCSS(colorMap: CarColorMap, carColor: string) {
  const colors = colorMap[carColor.toLowerCase()] || colorMap["other"];

  return {
    color: colors.color,
    borderColor: colors.borderColor || colors.color,
  };
}

const StyledLocatorCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  padding-right: 1rem;
`;

interface StyledVehicleColorDotProps {
  vehicleColor: string;
}

const StyledVehicleColorDot = styled.div<
  StyledVehicleColorDotProps & SpaceProps
>`
  ${space};
  height: 1.25rem;
  width: 1.25rem;
  background-color: ${(props) =>
    mapColorToCSS(props.theme.colors.vehicleColors, props.vehicleColor).color};
  border-radius: 50%;
  border: solid;
  border-color: ${(props) =>
    mapColorToCSS(props.theme.colors.vehicleColors, props.vehicleColor)
      .borderColor};
  border-width: 1px;
`;

const StyledZone = styled.div`
  font-size: 0.875rem;
`;
