import React, { ComponentPropsWithRef, forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { Box } from "../box/Box";
import { Icon } from "../icon/icon";

interface Props extends ComponentPropsWithRef<"input"> {
  icon?: ReactNode;
  onClear?: () => void;
}

export default forwardRef<HTMLInputElement, Props>(function Input(
  { icon, onClear, ...props },
  ref
) {
  return (
    <Box position="relative">
      {icon && (
        <Box position="absolute" top={0} bottom={0} p="0.5rem">
          {icon}
        </Box>
      )}
      <StyledInput
        ref={ref}
        {...props}
        pr={onClear ? "2rem" : "0.5rem"}
        pl={icon ? "2.5rem" : "0.5rem"}
      />
      {props.value && onClear && (
        <Box
          className="clear-button"
          as="button"
          background="transparent"
          border="none"
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          p="0.75rem"
          onClick={onClear}
        >
          <Icon alt="clear" imageSrc="/icons/dark-grey-cancel.svg" />
        </Box>
      )}
    </Box>
  );
});

const StyledInput = styled.input<SpaceProps>`
  padding: 0.5rem;
  ${space};
  width: 100%;
  color: ${(props) => props.theme.colors?.tertiary};
  max-width: 30rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  border-color: transparent;
  box-shadow: 0px 0px 5px 0.1px ${(props) => props.theme.colors?.outline};
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px 0.1px ${(props) => props.theme.colors?.highlight};
  }
  ::placeholder {
    color: ${(props) => props.theme?.colors?.outline};
  }
`;
