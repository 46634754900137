import React, { useState } from "react";
import { Order } from "../../../models/order";
import {
  ADD_PINNED_ORDER,
  REMOVE_PINNED_ORDER,
} from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";
import { PinCheckbox } from "../../base/pin-checkbox/pin-checkbox";

interface Props {
  order: Order;
}

export function Pinned({ order }: Props) {
  const dispatch = useRadarDispatch();
  const { pinnedOrders } = useRadarState();
  const [isChecked, setIsChecked] = useState(
    pinnedOrders.some((pinnedOrder) => pinnedOrder.id === order.id)
  );

  return (
    <PinCheckbox
      checked={isChecked}
      onChange={() => {
        isChecked
          ? dispatch({ type: REMOVE_PINNED_ORDER, orderId: order.id })
          : dispatch({ type: ADD_PINNED_ORDER, order: order });

        setIsChecked(!isChecked);
      }}
    />
  );
}
