import React, { FunctionComponent, ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import { justifyContent, JustifyContentProps } from "styled-system";
import { ReactComponent as CaretLeft } from "../../../components/icons/caret-left.svg";

type SidebarProps = {
  visible: boolean;
  onBack?: () => void;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  className?: string;
};

const SidebarDisplay = styled.aside`
  overflow: auto;
  height: 100%;
  background-color: white;
  color: ${(props) => props.theme?.colors?.text};
  position: fixed;
  height: calc(100vh - 80px);
  width: 20rem;
  right: -20rem;
  z-index: 2;
  transition: 0.5s all ease;
  box-shadow: -2px 0 4px 0 rgba(3, 3, 3, 0.15);
  font-weight: bold;
  font-size: 0.875rem;
  padding: 1.5rem;
  visibility: hidden;
  &:focus {
    outline: none;
  }
  &.visible {
    transform: translate3d(-20rem, 0, 0);
    visibility: visible;
  }
`;

const TitleBar = styled.div`
  display: flex;
  margin-bottom: 1.75rem;
`;

const TitleBarItem = styled.div<JustifyContentProps>`
  flex: 1;
  display: flex;
  align-items: center;
  ${justifyContent}
`;

const TitleBarTitle = styled.span`
  font-size: 1rem;
`;

const TitleBarButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  :hover {
    cursor: pointer;
  }
`;

const Sidebar: FunctionComponent<SidebarProps> = ({
  onBack,
  onClose,
  title,
  visible,
  children,
  className,
}) => {
  const displayRef = useRef<HTMLElement>();

  // When the sidebar opens, set focus to it
  useEffect(() => {
    if (displayRef.current != null && visible) {
      displayRef.current.focus();
    }
  }, [displayRef, visible]);

  // When sidebar is open, esc closes
  useEffect(() => {
    function handleEscPress(e: KeyboardEvent) {
      if (e.key === "Escape" && onClose != null) {
        // escape key
        onClose();
      }
    }

    if (visible) {
      document.addEventListener("keydown", handleEscPress, false);

      return function cleanup() {
        document.removeEventListener("keydown", handleEscPress, false);
      };
    }
  }, [onClose, visible]);

  return (
    <SidebarDisplay
      tabIndex={-1}
      ref={(el) => {
        if (el) {
          displayRef.current = el;
        }
      }}
      className={`${className} ${visible ? "visible" : ""}`}
    >
      <TitleBar>
        <TitleBarItem justifyContent="flex-start">
          {onBack && (
            <TitleBarButton data-cy="sidebar-back" onClick={onBack}>
              <CaretLeft fill="#004f71" height={16} width={8} />
            </TitleBarButton>
          )}
        </TitleBarItem>

        <TitleBarItem justifyContent="center">
          {title && <TitleBarTitle>{title}</TitleBarTitle>}
        </TitleBarItem>

        <TitleBarItem justifyContent="flex-end">
          {onClose && (
            <input
              data-cy="sidebar-close"
              type="image"
              src="/icons/dark-grey-cancel.svg"
              alt="close sidebar"
              onClick={onClose}
            />
          )}
        </TitleBarItem>
      </TitleBar>
      {children}
    </SidebarDisplay>
  );
};

export default Sidebar;
