export function getPage<T>(items: T[], pageNum: number, pageSize: number): T[] {
  let startIndex = pageSize * pageNum;
  let endIndex = startIndex + pageSize;
  if (endIndex > items.length) {
    startIndex = items.length - pageSize <= 0 ? 0 : items.length - pageSize;
    endIndex = items.length;
  }

  return items.slice(startIndex, endIndex);
}
