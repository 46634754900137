import React from "react";
import styled from "styled-components";

export interface HeaderTabProps {
  name: string;
  onTabSelected: () => void;
  isActive: boolean;
}

export interface HeaderTabContainerProps {
  tabs: HeaderTabProps[];
}

export function HeaderTabs(props: HeaderTabContainerProps) {
  const { tabs } = props;

  return (
    <StyledTabs>
      {tabs.map((tab, index) => {
        return (
          <Tab key={index} className={tab.isActive ? "active" : ""}>
            <TabButton
              className={tab.isActive ? "active" : ""}
              onClick={() => {
                tab.onTabSelected();
              }}
            >
              {tab.name}
            </TabButton>
          </Tab>
        );
      })}
    </StyledTabs>
  );
}

const StyledTabs = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;

const Tab = styled.li`
  display: flex;
  height: 100%;
  &:not(:first-child) {
    margin-left: 1rem;
  }
  &.active {
    border-bottom: solid ${(props) => props.theme?.colors?.tertiary};
  }
`;

const TabButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  background-color: transparent;
  color: ${(props) => props.theme?.colors?.outline};
  &.active {
    color: ${(props) => props.theme?.colors?.tertiary};
    cursor: default;
  }
`;
