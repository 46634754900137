import * as React from "react";
import { useUserPrefPersistReducer } from "../hooks/useUserPrefsPersistReducer";
import { Order } from "../models/order";
import {
  Action,
  ADD_PINNED_ORDER,
  CLOSE_SIDEBAR,
  LOAD_USER_PREFS,
  OPEN_SIDEBAR,
  REMOVE_PINNED_ORDER,
  SET_AUTO_CLEAR_TIMEOUTS,
  SET_CUSTOMER_VIEW_TYPE,
  SET_FILTERS,
  SET_FONT_SIZE,
  SET_ORDERS,
  SET_ORDERS_PER_TABLE,
  SET_SORT,
  SET_TABLE_COUNT,
  SET_USER,
  SET_VIEW_TYPE,
  UPDATE_LOCATION,
  UPDATE_LOCATION_LIST,
  UPDATE_ORDER,
} from "./actions";
import { initialState, OrderState, State } from "./state";

export type Dispatch = (action: Action) => void;

const RadarStateContext = React.createContext<State | undefined>(undefined);
const RadarDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function radarReducer(state: State, action: Action): State {
  switch (action.type) {
    case UPDATE_LOCATION:
      return { ...state, selectedLocation: action.location };
    case UPDATE_LOCATION_LIST:
      return { ...state, locations: action.locations };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.viewType]: {
            ...state.filters[action.viewType],
            [action.filterCategory]: action.fields,
          },
        },
      };
    case SET_SORT:
      return {
        ...state,
        settings: { ...state.settings, sortType: action.sortType },
      };
    case SET_ORDERS: {
      return {
        ...state,
        orders: action.orders,
      };
    }
    case UPDATE_ORDER: {
      let orderFound = false;
      let newOrderList: Order[] = [];

      if (state.orders.orders != null) {
        newOrderList = state.orders.orders.map((order: Order) => {
          if (order.id === action.order.id) {
            orderFound = true;
            return action.order;
          }
          return order;
        });
      }

      if (orderFound === false) {
        newOrderList.push(action.order);
      }

      const newOrderState: OrderState = {
        ...state.orders,
        orders: newOrderList,
      };
      return { ...state, orders: newOrderState };
    }
    case OPEN_SIDEBAR: {
      return {
        ...state,
        sidebar: {
          isOpen: true,
          type: action.sidebarType,
        },
      };
    }
    case CLOSE_SIDEBAR: {
      return {
        ...state,
        sidebar: {
          isOpen: false,
        },
      };
    }
    case SET_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.viewType,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    case LOAD_USER_PREFS: {
      return {
        ...state,
        ...action.userPreferences,
      };
    }
    case SET_AUTO_CLEAR_TIMEOUTS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          autoClearTimeouts: {
            ...state.settings.autoClearTimeouts,
            [action.status]: action.timeout,
          },
        },
      };
    }
    case SET_ORDERS_PER_TABLE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          customerViewOrdersPerTable: action.numberOfOrders,
        },
      };
    }
    case SET_FONT_SIZE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          customerViewFontSize: action.fontSize,
        },
      };
    }
    case SET_TABLE_COUNT: {
      return {
        ...state,
        settings: {
          ...state.settings,
          customerViewTableCount: action.numberOfTables,
        },
      };
    }
    case SET_CUSTOMER_VIEW_TYPE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          customerViewType: action.viewType,
        },
      };
    }
    case ADD_PINNED_ORDER: {
      return {
        ...state,
        pinnedOrders: [...state.pinnedOrders, action.order],
      };
    }
    case REMOVE_PINNED_ORDER: {
      const newPinnedOrders = state.pinnedOrders.filter(
        ({ id }) => id !== action.orderId
      );

      return {
        ...state,
        pinnedOrders: newPinnedOrders,
      };
    }
    default:
      return state;
  }
}

type RadarProviderProps = { children: React.ReactNode };
function RadarProvider({ children }: RadarProviderProps) {
  const [state, dispatch] = useUserPrefPersistReducer(
    radarReducer,
    initialState
  );

  return (
    <RadarDispatchContext.Provider value={dispatch}>
      <RadarStateContext.Provider value={state}>
        {children}
      </RadarStateContext.Provider>
    </RadarDispatchContext.Provider>
  );
}

function useRadarState(): State {
  const context = React.useContext(RadarStateContext);
  if (context == null) {
    throw new Error("useRadarState must be used within a RadarProvider");
  }
  return context;
}

function useRadarDispatch(): Dispatch {
  const context = React.useContext(RadarDispatchContext);
  if (context == null) {
    throw new Error("useRadarDispatch must be used within a RadarProvider");
  }
  return context;
}

export { RadarProvider, useRadarState, useRadarDispatch };
