import moment from "moment";
import { useEffect, useState } from "react";
import { OrderStatus, OrderTimestamps } from "../../models/order";
import { ViewType } from "../../models/view";
import { useRadarState } from "../../reducers/reducers";

interface Props {
  viewType: ViewType;
  status: OrderStatus;
  timestamps?: OrderTimestamps;
}

export function useDelayedStatus({ viewType, status, timestamps }: Props) {
  const { selectedLocation } = useRadarState();
  const [debouncedStatus, setDebouncedStatus] = useState<OrderStatus | null>(
    null
  );
  const isStatusDelayed =
    viewType === ViewType.CUSTOMER &&
    selectedLocation?.mobileOrderingSettings.isNotificationDelayed;

  useEffect(() => {
    if (isStatusDelayed) {
      if (status === OrderStatus.Ready && timestamps?.ready) {
        const delay = moment(timestamps.ready).diff(
          moment().subtract(
            selectedLocation?.mobileOrderingSettings
              .notificationDelayInSeconds || 0,
            "seconds"
          ),
          "seconds"
        );

        if (delay > 0) {
          const timer = setTimeout(() => {
            setDebouncedStatus(status);
          }, delay * 1000);

          setDebouncedStatus(OrderStatus.CheckIn);
          return () => clearTimeout(timer);
        }
      }

      if (status !== debouncedStatus) {
        setDebouncedStatus(status);
      }
    }
  }, [selectedLocation, status, timestamps, debouncedStatus, isStatusDelayed]);

  const delayedStatus = isStatusDelayed ? debouncedStatus : status;

  return { isStatusDelayed, delayedStatus };
}
