import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

type ToggleButtonProps = {
  active: boolean;
  onToggle: (ev?: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
};

const StyledToggleButton = styled.button`
  border: 1px solid ${(props) => props.theme?.colors?.highlight};
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(220, 240, 245, 0.5);
  color: ${(props) => props.theme?.colors?.tertiary};
  font-weight: bold;
  font-size: 0.875rem;
  cursor: pointer;
  &.inactive {
    border: 1px solid ${(props) => props.theme?.colors?.outline};
    color: ${(props) => props.theme?.colors?.text};
    background-color: ${(props) => props.theme?.colors?.secondary};
  }
`;

const ToggleButton: FunctionComponent<ToggleButtonProps> = (props) => {
  return (
    <StyledToggleButton
      {...props}
      className={props.active ? "" : "inactive"}
      onClick={props.onToggle}
    >
      {props.children}
    </StyledToggleButton>
  );
};
export default ToggleButton;
