export interface Order {
  id: string;
  locationNumber: string;
  status: OrderStatus;
  user: OrderUser;
  fulfillment: OrderFulfillment;
  timestamps: OrderTimestamps;
  origin: { id: string };
  drinkCount: number;
  treatCount: number;
  entreeCount: number;
}

export interface OrderUser {
  firstName: string;
  lastName: string;
}

export interface OrderFulfillment {
  destination: OrderFulfillmentDestination;
  zones: OrderFulfillmentZones;
  vehicle: OrderFulfillmentVehicle;
}

export interface OrderTimestamps {
  submit: string;
  checkIn: string;
  ready: string;
  error: string;
  complete: string;
}

export interface OrderFulfillmentZones {
  dineIn: string;
  kpsFulfillmentDetails: string;
  radius: string;
}

export interface OrderFulfillmentVehicle {
  id: string;
  make: string;
  model: string;
  color: string;
}

export enum OrderFulfillmentDestination {
  DELIVERY = "Delivery",
  CURBSIDE = "Curbside",
  ON_DEMAND = "onDemand",
  CARRY_OUT = "CarryOut",
  DINE_IN = "DineIn",
  DRIVE_THRU = "DriveThru",
  PICKUP_WINDOW = "PickupWindow",
  PICKUP = "Pickup",
  DOOR_DASH = "DoorDash",
}

export enum OrderStatus {
  Cart = "Cart",
  Create = "Create",
  Submit = "Submit",
  CheckIn = "CheckIn",
  Ready = "Ready",
  Complete = "Complete",
  Cancelled = "Cancelled",
  Error = "Error",
}
