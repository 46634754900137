import { useCallback, useEffect } from "react";

import { useLazyQuery, useSubscription } from "@apollo/client";
import { Order } from "../models/order";
import { ORDERS_QUERY, ORDERS_SUBSCRIPTION } from "../queries/order";
import { useRadarDispatch, useRadarState } from "../reducers/reducers";

import { SET_ORDERS, UPDATE_ORDER } from "../reducers/actions";

export type OrderQueryRes = { orders: Order[] };
export type OrderSubRes = { orderUpdated: Order };
export type OrderQueryVars = { locationNumber: string };

export const useOrderFetch = () => {
  const {
    selectedLocation,
    user,
    settings: { orderRefetchIntervalInMS },
  } = useRadarState();
  const dispatch = useRadarDispatch();

  // Using no-cache because we only do a full query when we want the full set of data (EG New location loaded)
  const [getOrders, { error, loading, data }] = useLazyQuery<
    OrderQueryRes,
    OrderQueryVars
  >(ORDERS_QUERY, { fetchPolicy: "no-cache" });

  // When the location or authentication changes, fetch orders for new location
  const callGetOrders = useCallback(() => {
    if (
      user != null &&
      selectedLocation != null &&
      selectedLocation.locationNumber !== ""
    ) {
      getOrders({
        variables: { locationNumber: selectedLocation.locationNumber },
      });
    }
  }, [getOrders, selectedLocation, user]);

  useEffect(() => {
    callGetOrders();

    // Set up timer to periodically refetch all data to insure data is in sync
    const intervalId = setInterval(() => {
      callGetOrders();
    }, orderRefetchIntervalInMS);

    return () => {
      clearInterval(intervalId);
    };
  }, [callGetOrders, orderRefetchIntervalInMS]);

  // Set up a subscription to listen for changes to orders and update state
  // NOTE: React strict mode (in development env only) has a side-effect of
  // double-subscribing.
  useSubscription<OrderSubRes>(ORDERS_SUBSCRIPTION, {
    skip: selectedLocation == null,
    variables: { locationNumber: selectedLocation?.locationNumber },
    onData: ({ data }) => {
      const updatedOrder = data.data?.orderUpdated;

      if (updatedOrder) {
        dispatch({ type: UPDATE_ORDER, order: updatedOrder });
      }
    },
  });

  // When the data object is updated by the query, set the state
  useEffect(() => {
    dispatch({
      type: SET_ORDERS,
      orders: {
        loading,
        error,
        orders: data?.orders as Order[],
      },
    });

    // eslint-disable-next-line
  }, [error, loading, data?.orders]);
};
