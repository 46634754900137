import styled from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  height,
  HeightProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  width,
  WidthProps,
} from "styled-system";

export type BoxProps = SpaceProps &
  PositionProps &
  FlexboxProps &
  TypographyProps &
  ColorProps &
  DisplayProps &
  WidthProps &
  HeightProps &
  BackgroundProps &
  BorderProps &
  BoxShadowProps &
  OverflowProps;

export const Box = styled("div")<BoxProps>(
  compose(
    space,
    position,
    flexbox,
    typography,
    color,
    display,
    width,
    height,
    background,
    border,
    boxShadow,
    overflow
  )
);
