import { useCallback, useEffect, useReducer } from "react";
import { UserPreferences } from "../models/user-preferences";
import { Action, LOAD_USER_PREFS, SET_USER } from "../reducers/actions";
import { State } from "../reducers/state";

const STORAGE_KEY_PREFIX = "vp-radar-user-prefs";

export function useUserPrefPersistReducer(
  reducer: (state: State, action: Action) => State,
  initialState: State
): [State, React.Dispatch<Action>] {
  const [state, originalDispatch] = useReducer(reducer, initialState);
  const { viewType, selectedLocation, filters, user, settings, pinnedOrders } =
    state;

  const dispatchWrapper: React.Dispatch<Action> = useCallback(
    (action: Action) => {
      if (action.type === SET_USER && action.user?.login) {
        const json = localStorage.getItem(STORAGE_KEY_PREFIX);

        if (json != null) {
          const userPreferences: UserPreferences = JSON.parse(json);

          originalDispatch({
            type: LOAD_USER_PREFS,
            userPreferences,
          });
        }
      }

      originalDispatch(action);
    },
    [originalDispatch]
  );

  useEffect(() => {
    if (user == null) {
      return;
    }

    const userPreferences = {
      viewType,
      selectedLocation,
      filters,
      settings,
      pinnedOrders,
    };

    localStorage.setItem(STORAGE_KEY_PREFIX, JSON.stringify(userPreferences));
  }, [viewType, selectedLocation, filters, user, settings, pinnedOrders]);

  return [state, dispatchWrapper];
}
