import { createGlobalStyle, DefaultTheme } from "styled-components";
import { OrderStatus } from "./models/order";

export const CFATheme: DefaultTheme = {
  colors: {
    primary: "#DD0031",
    secondary: "#ffffff",
    tertiary: "#004F71",
    background: "#f5f5f5",
    highlight: "#34a1b7",
    text: "#5B6770",
    outline: "#aaaaaa",
    disabled: "#e5e0e0",
    success: "#249E6B",
    error: "rgba(246,105,105,1)",
    warning: "#cca206",
    statuses: {
      [OrderStatus.CheckIn]: "rgba(255, 181, 73, 0.3)",
      [OrderStatus.Complete]: "#DBF0F5",
      [OrderStatus.Error]: "rgba(246,105,105,0.3)",
      [OrderStatus.Ready]: "rgba(36,158,107,0.3)",
      [OrderStatus.Submit]: "rgb(245, 227, 204, 0.3)",
      [OrderStatus.Cart]: "rgba(255,255,255, 0.0)",
      [OrderStatus.Create]: "rgba(255,255,255, 0.0)",
      [OrderStatus.Cancelled]: "rgba(255,255,255, 0.0)",
    },
    vehicleColors: {
      white: { color: "#F7F7F7", borderColor: "#979797" },
      silver: { color: "#DBE0E2" },
      black: { color: "#030303" },
      gray: { color: "#5B6770" },
      red: { color: "#DD0031" },
      blue: { color: "#004F71" },
      green: { color: "#05847A" },
      brown: { color: "#613907" },
      gold: { color: "#F5E1A4" },
      orange: { color: "#EB5623" },
      other: { color: "#F8C1B6" },
    },
  },
  fonts: {
    primary: "Apercu, Arial, sans-serif",
  },
  baseFontSize: 4,
};

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Apercu';
    src: url('/apercu.woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
      font-family: 'Apercu';
      src: url('/apercu-bold.woff');
      font-weight: bold;
      font-style: normal;
  }

  #root {
    height: 100%;
  }
  
  html,
  body {
      height: 100%;
      padding: 0;
      margin: 0;
      font-family: ${(props) => props.theme.fonts.primary};
      line-height: 1.6;
      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 600px) {
        font-size: ${(props) => props.theme.baseFontSize}px;
      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        font-size: ${(props) => props.theme.baseFontSize * 2}px;
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        font-size: ${(props) => props.theme.baseFontSize * 3}px;
      }

      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        font-size: ${(props) => props.theme.baseFontSize * 4}px;
      }

      /* Extra large devices (large laptops and desktops, 2000px and up) */
      @media only screen and (min-width: 2000px) {
        font-size: ${(props) => props.theme.baseFontSize * 5}px;
      }

      @media only screen and (min-width: 3000px) {
        font-size: ${(props) => props.theme.baseFontSize * 6}px;
      }

      @media only screen and (min-width: 4000px) {
        font-size: ${(props) => props.theme.baseFontSize * 12}px;
      }
  }
  
  * {
      box-sizing: border-box;
  }
  
  a {
      text-decoration: none;
  }
  
  a:hover {
      text-decoration: underline;
  }
  
  img {
      max-width: 100%;
      display: block;
  }

  button {
    :hover {
      cursor: pointer;
    }
  }
`;
