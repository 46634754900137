import moment from "moment";
import React, { useEffect, useState } from "react";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { getLocation } from "../../api/location";

import { UPDATE_LOCATION } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import {
  FilterDropDown,
  FilterDropDownItem,
} from "../base/filter-drop-down/filter-drop-down";

export const LocationFilterDropDown = () => {
  const { locations, selectedLocation } = useRadarState();
  const dispatch = useRadarDispatch();
  const [filterItems, setFilterItems] = useState<FilterDropDownItem[]>([]);

  useEffect(() => {
    setFilterItems(
      locations.map((location) => ({
        id: location.locationNumber,
        displayName: `${location.locationNumber} ${location.name}`,
      }))
    );
  }, [locations]);

  const onSelectItem = async (locationNumber: string) => {
    const selectedLocation = await getLocation(locationNumber);
    const storageTimestamp = moment().add(1, "day").toString();

    dispatch({
      type: UPDATE_LOCATION,
      location: {
        ...selectedLocation,
        storageTimestamp,
      },
    });
    logAmplitudeEvent(LogEvent.UpdateLocation, {
      LOCATION_NUMBER: locationNumber,
    });
  };

  return (
    <FilterDropDown
      itemPluralName="Stores"
      pageSize={10}
      selectedItem={`${selectedLocation?.locationNumber} ${selectedLocation?.name}`}
      items={filterItems}
      onSelectItem={onSelectItem}
    />
  );
};
