import { useOktaAuth } from "@okta/okta-react";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { logAmplitudeEvent, setAmplitudeUser } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { getEnvConfig } from "../../lib/config/config";
import { FilterCategory } from "../../models/filter";
import { OrderFulfillmentDestination, OrderStatus } from "../../models/order";
import { SidebarType } from "../../models/sidebar";
import { SortType } from "../../models/sort";
import { ViewType } from "../../models/view";
import {
  CUSTOMER_ORDERS_PATH,
  RESTAURANT_ORDERS_PATH,
} from "../../pages/page-routing-config";
import { OPEN_SIDEBAR, SET_SORT } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { mapDestinationToDisplayName } from "../../utils/mappers/destinationMapper";
import {
  mapStatusToDisplayNameCustomerSidebarFilter,
  mapStatusToDisplayNameRestaurantView,
} from "../../utils/mappers/statusMapper";
import { RADAR_VERSION } from "../../version";
import { Box } from "../base/box/Box";
import { DropDown } from "../base/drop-down/drop-down";
import Sidebar from "../base/sidebar/sidebar";
import { SidebarButton } from "../base/sidebar/sidebar-button";
import { SidebarGroup } from "../base/sidebar/sidebar-group";
import { SidebarLink } from "../base/sidebar/sidebar-link";
import { SidebarText } from "../base/sidebar/sidebar-text";
import { FilterGroup } from "../filter-group/filter-group";
import { ReactComponent as CaretRight } from "../icons/caret-right.svg";

type Props = {
  viewType: ViewType;
  visible: boolean;
  onCloseClicked: () => void;
  categories: FilterCategory[];
};

export const MenuSidebar: FunctionComponent<Props> = ({
  categories,
  onCloseClicked,
  viewType,
  visible,
}) => {
  const history = useHistory();
  const {
    Okta: { logoutUri },
    Locations: { allowedCustomerFacingView },
  } = getEnvConfig();
  const {
    selectedLocation,
    settings: { sortType },
  } = useRadarState();
  const dispatch = useRadarDispatch();
  const { oktaAuth } = useOktaAuth();

  const onChange = (sortType: SortType) => {
    dispatch({ type: SET_SORT, sortType });
    logAmplitudeEvent(LogEvent.UpdateSort, {
      TYPE: sortType,
      VIEW_TYPE: viewType,
    });
  };

  const onRestaurantViewClick = () => {
    history.push(RESTAURANT_ORDERS_PATH);
  };

  const onLogout = async () => {
    await oktaAuth.signOut({ postLogoutRedirectUri: logoutUri });
    logAmplitudeEvent(LogEvent.LogOut);
    setAmplitudeUser(null);
  };

  return (
    <Sidebar
      visible={visible}
      onClose={onCloseClicked}
      className="menu-sidebar"
    >
      <div style={{ paddingBottom: "0.75rem" }}>
        <span>Sort by</span>
      </div>
      <DropDown
        defaultValue={sortType}
        onChange={onChange}
        options={[
          { value: SortType.NEWEST_AT_TOP, text: "Newest orders on top" },
          { value: SortType.OLDEST_AT_TOP, text: "Oldest orders on top" },
        ]}
      />
      {categories.includes(FilterCategory.DESTINATION) && (
        <FilterGroup
          viewType={viewType}
          category={FilterCategory.DESTINATION}
          options={[
            OrderFulfillmentDestination.DELIVERY,
            OrderFulfillmentDestination.ON_DEMAND,
            OrderFulfillmentDestination.CARRY_OUT,
            OrderFulfillmentDestination.CURBSIDE,
            OrderFulfillmentDestination.DINE_IN,
            OrderFulfillmentDestination.DRIVE_THRU,
          ].map((destination) => {
            return {
              id: destination.toString(),
              name: mapDestinationToDisplayName(destination),
            };
          })}
        />
      )}

      {categories.includes(FilterCategory.STATUS) && (
        <FilterGroup
          viewType={viewType}
          category={FilterCategory.STATUS}
          options={[
            OrderStatus.Ready,
            OrderStatus.CheckIn,
            OrderStatus.Submit,
            OrderStatus.Error,
          ].map((status) => {
            return {
              id: status.toString(),
              name:
                viewType === ViewType.CUSTOMER
                  ? mapStatusToDisplayNameCustomerSidebarFilter(status)
                  : mapStatusToDisplayNameRestaurantView(status),
            };
          })}
        />
      )}

      <Box mt="1.5rem">
        {viewType === ViewType.RESTAURANT ? (
          <>
            {
              // Onle show customer-facing view if selected location is allowed
              selectedLocation &&
                allowedCustomerFacingView.includes(
                  selectedLocation?.locationNumber
                ) && (
                  <SidebarGroup>
                    <SidebarButton
                      data-cy="customer-view-button"
                      onClick={() => {
                        history.push(CUSTOMER_ORDERS_PATH);
                      }}
                    >
                      <SidebarText>
                        Customer-facing view
                        <StyledBetaText> - BETA</StyledBetaText>
                      </SidebarText>
                      <CaretRight fill="#004f71" height={16} width={16} />
                    </SidebarButton>
                  </SidebarGroup>
                )
            }
            <SidebarGroup>
              <SidebarLink
                data-cy="feedback-button"
                target="_blank"
                href={getEnvConfig().Feedback.httpEndpoint}
              >
                <SidebarText>Give feedback</SidebarText>
                <CaretRight fill="#004f71" height={16} width={16} />
              </SidebarLink>
            </SidebarGroup>
            <SidebarGroup>
              <SidebarButton
                data-cy="settings-slideout-button"
                onClick={() => {
                  dispatch({
                    type: OPEN_SIDEBAR,
                    sidebarType: SidebarType.SETTINGS,
                  });
                }}
              >
                <SidebarText>Settings</SidebarText>
                <CaretRight fill="#004f71" height={16} width={16} />
              </SidebarButton>
            </SidebarGroup>
            <SidebarGroup>
              <SidebarButton data-cy="logout-button" onClick={onLogout}>
                <SidebarText>Log out</SidebarText>
                <CaretRight fill="#004f71" height={16} width={16} />
              </SidebarButton>
            </SidebarGroup>
          </>
        ) : (
          <>
            <SidebarGroup>
              <SidebarButton
                data-cy="restaurant-view-button"
                onClick={onRestaurantViewClick}
              >
                <SidebarText>Restaurant View</SidebarText>
                <CaretRight fill="#004f71" height={16} />
              </SidebarButton>
            </SidebarGroup>
            <SidebarGroup>
              <SidebarButton
                data-cy="settings-slideout-button"
                onClick={() => {
                  dispatch({
                    type: OPEN_SIDEBAR,
                    sidebarType: SidebarType.SETTINGS,
                  });
                }}
              >
                <SidebarText>Settings</SidebarText>
                <CaretRight fill="#004f71" height={16} />
              </SidebarButton>
            </SidebarGroup>
            <SidebarGroup>
              <SidebarButton data-cy="logout-button" onClick={onLogout}>
                <SidebarText>Log out</SidebarText>
                <CaretRight fill="#004f71" height={16} />
              </SidebarButton>
            </SidebarGroup>
          </>
        )}
      </Box>

      <Box data-cy="vpr-version" fontSize="0.75rem">
        {RADAR_VERSION}
      </Box>
    </Sidebar>
  );
};

const StyledBetaText = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme?.colors?.highlight};
`;
