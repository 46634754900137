import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocationFetch } from "../../../actions/location-fetch";
import { LogEvent } from "../../../analytics/types";
import { usePageViewAnalytic } from "../../../analytics/usePageViewAnalytic";
import { getLocation } from "../../../api/location";
import { useDebounce } from "../../../hooks/useDebounce";
import { OrdersTab } from "../../../models/orders-tab";
import { ViewType } from "../../../models/view";
import { SET_VIEW_TYPE, UPDATE_LOCATION } from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";
import { OrdersViewTemplate } from "../orders-view-template";
import { RestaurantViewContent } from "./restaurant-view-content";
import { RestaurantViewHeader } from "./restaurant-view-header";

export function RestaurantView() {
  const { locations, selectedLocation, filters, settings } = useRadarState();
  const [ordersTab, setOrdersTab] = useState<OrdersTab>(OrdersTab.CURRENT);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText.trim());

  useEffect(() => {
    dispatch({ type: SET_VIEW_TYPE, viewType: ViewType.RESTAURANT });
  }, []);

  usePageViewAnalytic(LogEvent.View, {
    viewType: ViewType.RESTAURANT,
    tab: ordersTab,
    locationName: selectedLocation?.name,
    locationNumber: selectedLocation?.locationNumber,
    destinationFilters: filters?.restaurant?.DESTINATION,
    statusFilters: filters?.restaurant?.STATUS,
    sortType: settings?.sortType,
    bumpTimeout: settings?.autoClearTimeouts.Ready,
  });

  const dispatch = useRadarDispatch();
  useLocationFetch();

  useEffect(() => {
    const setDefaultLocation = async () => {
      // If there is no selected location, or if the selected location is not in the
      // list of filtered locations, set location to first in list
      if (
        locations?.length > 0 &&
        (!selectedLocation?.storageTimestamp ||
          !locations.find(
            (location) =>
              location.locationNumber === selectedLocation?.locationNumber
          ) ||
          moment(new Date(selectedLocation.storageTimestamp)).isBefore(
            moment.now()
          ))
      ) {
        const newLocation = await getLocation(
          selectedLocation?.locationNumber || locations[0].locationNumber
        );

        dispatch({
          type: UPDATE_LOCATION,
          location: {
            ...newLocation,
            storageTimestamp: moment().add(1, "day").toString(),
          },
        });
      }
    };

    setDefaultLocation();
  }, [dispatch, locations, selectedLocation]);

  const headerContent = (
    <RestaurantViewHeader
      ordersTab={ordersTab}
      onOrdersTabSelected={(selectedOrdersTab: OrdersTab) =>
        setOrdersTab(selectedOrdersTab)
      }
      searchText={searchText}
      onSearchChange={setSearchText}
    />
  );

  const bodyContent = (
    <RestaurantViewContent
      ordersTab={ordersTab}
      searchText={debouncedSearchText}
    />
  );

  return (
    <OrdersViewTemplate
      headerContent={headerContent}
      bodyContent={bodyContent}
    />
  );
}
