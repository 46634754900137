import React from "react";
import styled from "styled-components";
import { Box } from "../../../components/base/box/Box";
import { HeaderIcon } from "../../../components/base/header-icon/header-icon";
import { HeaderTabs } from "../../../components/base/header-tabs/header-tabs";
import {
  HeaderText,
  HeaderTextSize,
  HeaderTextVariant,
} from "../../../components/base/header-text/header-text";
import { Icon } from "../../../components/base/icon/icon";
import Input from "../../../components/base/input/Input";
import { LocationFilterDropDown } from "../../../components/location-filter-dropdown/location-filter-dropdown";
import { OrdersTab } from "../../../models/orders-tab";
import { SidebarType } from "../../../models/sidebar";
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";

export interface RestaurantViewHeaderProps {
  ordersTab: OrdersTab;
  onOrdersTabSelected: (ordersTab: OrdersTab) => void;
  searchText: string;
  onSearchChange: (text: string) => void;
}

export function RestaurantViewHeader({
  ordersTab,
  onOrdersTabSelected,
  searchText,
  onSearchChange,
}: RestaurantViewHeaderProps) {
  const { sidebar } = useRadarState();
  const dispatch = useRadarDispatch();

  return (
    <>
      <HeaderGroup className="headerLeft">
        <StyledLogo>
          <Icon imageSrc="/icons/cfa-logo.svg" alt="Chick-fil-A Circle Logo" />
        </StyledLogo>
        <PositionedHeaderText>
          <HeaderText
            variant={HeaderTextVariant.PRIMARY}
            size={HeaderTextSize.SMALL}
          >
            ViewPoint Radar
          </HeaderText>
        </PositionedHeaderText>
        <PositionedHeaderTabs>
          <HeaderTabs
            tabs={[
              {
                name: "Current queue",
                onTabSelected: () => {
                  onOrdersTabSelected(OrdersTab.CURRENT);
                },
                isActive: ordersTab === OrdersTab.CURRENT,
              },
              {
                name: "Completed orders",
                onTabSelected: () => {
                  onOrdersTabSelected(OrdersTab.COMPLETED);
                },
                isActive: ordersTab === OrdersTab.COMPLETED,
              },
            ]}
          />
        </PositionedHeaderTabs>
        <Box mx="1.25rem">
          <Input
            onClear={() => onSearchChange("")}
            icon={<Icon imageSrc="/icons/search.svg" alt="search" />}
            placeholder="Search by customer"
            value={searchText}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </Box>
      </HeaderGroup>

      <HeaderGroup className="headerRight" style={{ maxWidth: "20rem" }}>
        <LocationFilterDropDown />
        <HeaderIcon
          data-cy="menu-sidebar"
          onClick={() => {
            if (sidebar.isOpen && sidebar.type === SidebarType.MENU) {
              dispatch({ type: CLOSE_SIDEBAR });
            } else {
              dispatch({
                type: OPEN_SIDEBAR,
                sidebarType: SidebarType.MENU,
              });
            }
          }}
          iconImageSrc="/icons/menu.svg"
          title="Menu"
        />
      </HeaderGroup>
    </>
  );
}

const StyledLogo = styled.div`
  height: 2.5rem;
`;

const PositionedHeaderText = styled.div`
  margin-left: 0.625rem;
  width: 5.875rem;
`;

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const PositionedHeaderTabs = styled.div`
  height: 100%;
`;
