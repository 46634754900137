import { FilterCategory } from "../../models/filter";

export function mapFilterCategoryToDisplayName(
  category: FilterCategory
): string {
  switch (category) {
    case FilterCategory.DESTINATION:
      return "Destination";
    case FilterCategory.STATUS:
      return "Order status";
    default:
      return "";
  }
}
