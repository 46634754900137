import { getEnvConfig } from "../lib/config/config";
import { oktaAuth } from "../lib/okta/okta";
import { Location } from "../models/location";

export const getLocation = async (
  locationNumber: string
): Promise<Location> => {
  const token = await oktaAuth.getAccessToken();
  const httpEndpoint = `${
    getEnvConfig().Locations.baseUrl
  }/locations/4.0/${locationNumber}`;

  return fetch(httpEndpoint, {
    headers: new Headers({
      Authorization: token ? `JWTBearer ${token}` : "",
    }),
  }).then((response) => response.json());
};
