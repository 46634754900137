import React, { ReactNode } from "react";
import styled from "styled-components";
import { variant } from "styled-system";

export enum HeaderTextVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum HeaderTextSize {
  SMALL = "small",
  LARGE = "large",
}

export interface HeaderTextProps {
  children: ReactNode | ReactNode[];
  variant: HeaderTextVariant;
  size: HeaderTextSize;
}

export const HeaderText = (props: HeaderTextProps) => {
  const { children } = props;

  return <StyledHeaderText {...props}>{children}</StyledHeaderText>;
};

export const StyledHeaderText = styled("span")(
  {
    fontFamily: "Apercu, sans-serif",
    fontWeight: "bold",
  },
  variant({
    prop: "variant",
    variants: {
      [HeaderTextVariant.PRIMARY]: {
        color: "primary",
        backgroundColor: "secondary",
      },
      [HeaderTextVariant.SECONDARY]: {
        color: "tertiary",
        backgroundColor: "secondary",
      },
    },
  }),
  variant({
    prop: "size",
    variants: {
      [HeaderTextSize.SMALL]: {
        fontSize: "1rem",
      },
      [HeaderTextSize.LARGE]: {
        fontSize: "1.25rem",
      },
    },
  })
);
