import React from "react";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { SET_CUSTOMER_VIEW_TYPE } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { CustomerViewType } from "../../reducers/state";
import { Select } from "../base/select/Select";

export function CustomerViewSetting() {
  const dispatch = useRadarDispatch();
  const {
    settings: { customerViewType },
  } = useRadarState();

  function onChange(viewType: CustomerViewType) {
    dispatch({
      type: SET_CUSTOMER_VIEW_TYPE,
      viewType,
    });
    logAmplitudeEvent(LogEvent.UpdateCustomerViewType, {
      TYPE: viewType,
    });
  }

  return (
    <Select
      value={customerViewType}
      onChange={onChange}
      options={[CustomerViewType.COLUMN, CustomerViewType.GRID]}
    />
  );
}
