import { OrderFulfillmentDestination } from "../../models/order";

export function mapDestinationToDisplayName(
  destination: OrderFulfillmentDestination
): string {
  switch (destination) {
    case OrderFulfillmentDestination.DELIVERY:
      return "Delivery";
    case OrderFulfillmentDestination.ON_DEMAND:
      return "On Demand";
    case OrderFulfillmentDestination.CARRY_OUT:
      return "Carry-out";
    case OrderFulfillmentDestination.CURBSIDE:
      return "Curbside";
    case OrderFulfillmentDestination.DINE_IN:
      return "Dine-in";
    case OrderFulfillmentDestination.DRIVE_THRU:
      return "Drive-thru";
    case OrderFulfillmentDestination.PICKUP_WINDOW:
      return "Pick-up window";
    case OrderFulfillmentDestination.PICKUP:
      return "Catering Pickup";
    case OrderFulfillmentDestination.DOOR_DASH:
      return "On Demand";
    default:
      return "";
  }
}
