export enum LogEvent {
  View = "View",
  UpdateCustomerViewType = "Update Customer View Type",
  UpdateBumpTimeout = "Update Bump Timeout",
  UpdateOrdersPerTable = "Update Orders Per Table",
  UpdateTableCount = "Update Table Count",
  UpdateFontSize = "Update Font Size",
  UpdateFilter = "Update Filter",
  UpdateSort = "Update Sort",
  UpdateLocation = "Update Location",
  CompleteOrder = "Complete Order",
  LogOut = "Log Out",
}
