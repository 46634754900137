import { ChickenSpinner } from "@cfacorp/cowponents";
import React, { useEffect, useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import styled from "styled-components";
import { Box } from "../../../components/base/box/Box";
import { MenuSidebar } from "../../../components/menu-sidebar/menu-sidebar";
import { DeliveryIcon } from "../../../components/orders-table/cell-types/delivery-icon";
import { Destination } from "../../../components/orders-table/cell-types/destination";
import { Locator } from "../../../components/orders-table/cell-types/locator";
import { Pinned } from "../../../components/orders-table/cell-types/pinned";
import { Status } from "../../../components/orders-table/cell-types/status";
import { OrdersTable } from "../../../components/orders-table/orders-table";
import { SettingsSidebar } from "../../../components/settings-sidebar/settings-sidebar";
import { DeliveryPartner } from "../../../models/delivery-partners";
import { FilterCategory } from "../../../models/filter";
import {
  Order,
  OrderFulfillmentDestination,
  OrderStatus,
} from "../../../models/order";
import { OrdersTab } from "../../../models/orders-tab";
import { SidebarType } from "../../../models/sidebar";
import { SortType } from "../../../models/sort";
import { ViewType } from "../../../models/view";
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";
import { AutoClearTimeouts, FilterConfig } from "../../../reducers/state";
import {
  filterByTimeInStatus,
  filterCompletedOlderThanToday,
  filterOrdersByConfig,
  filterOutStatus,
} from "../../../utils/filtering/order-filter";
import { mapStatusToDisplayNameRestaurantView } from "../../../utils/mappers/statusMapper";
import {
  sortOrdersByCompletedTimestamp,
  sortOrdersByRelevantTimestamp,
} from "../../../utils/sorting/order-sorter";

export interface Props {
  ordersTab: OrdersTab;
  searchText: string;
}

export function RestaurantViewContent({ ordersTab, searchText }: Props) {
  const dispatch = useRadarDispatch();
  const { orders, settings, sidebar, filters, pinnedOrders } = useRadarState();
  const [filteredSortedOrders, setFilteredSortedOrders] = useState<Order[]>([]);

  const filteredPinnedOrdersNotInOrders = useMemo(
    () =>
      pinnedOrders?.filter(
        (pinnedOrder) =>
          !orders.orders?.some((order) => pinnedOrder.id === order.id)
      ) || [],
    [orders.orders, pinnedOrders]
  );
  const filteredPinnedOrders = useMemo(
    () =>
      (pinnedOrders
        ?.map((pinnedOrder) =>
          orders.orders?.find((order) => pinnedOrder.id === order.id)
        )
        .filter(Boolean) as Order[]) || [],
    [orders.orders, pinnedOrders]
  );
  const filteredUnpinnedOrders = useMemo(
    () =>
      orders.orders?.filter(
        (order) =>
          !pinnedOrders?.some((pinnedOrder) => pinnedOrder.id === order.id)
      ) || [],
    [orders.orders, pinnedOrders]
  );

  // Change sort order for current queue, completed orders
  useEffect(() => {
    if (ordersTab === OrdersTab.CURRENT) {
      setFilteredSortedOrders(
        filterOrdersBySearch(
          sortOrdersByRelevantTimestamp(
            filterOrdersForRestaurantCurrentQueueView(
              orders.orders,
              filters[ViewType.RESTAURANT],
              settings.autoClearTimeouts
            ),
            settings.sortType === SortType.OLDEST_AT_TOP
          ),
          searchText
        )
      );
    } else {
      setFilteredSortedOrders(
        filterOrdersBySearch(
          sortOrdersByCompletedTimestamp(
            filterOrdersForRestaurantCompletedView(
              orders.orders,
              filters[ViewType.RESTAURANT],
              settings.autoClearTimeouts
            ),
            settings.sortType === SortType.OLDEST_AT_TOP
          ),
          searchText
        )
      );
    }
  }, [
    filters,
    ordersTab,
    searchText,
    orders.orders,
    settings.autoClearTimeouts,
    settings.sortType,
    pinnedOrders,
    filteredPinnedOrders,
    filteredUnpinnedOrders,
  ]);

  const columns: Column<Order>[] = React.useMemo(() => {
    return [
      {
        Header: "",
        id: "misc",
        accessor: (order) => order,
        width: 70,
        Cell: ({ value, row: { id } }: CellProps<Order, Order>) => (
          <Box display="flex" height="100%" width="100%" alignItems="center">
            {ordersTab === OrdersTab.CURRENT && <Pinned order={value} />}
            <Box ml="0.75rem">
              <DeliveryIcon
                data-cy={`vender-${id}`}
                id={
                  value?.fulfillment?.destination ===
                  OrderFulfillmentDestination.DOOR_DASH
                    ? DeliveryPartner.DOOR_DASH
                    : (value?.origin?.id as DeliveryPartner)
                }
              />
            </Box>
          </Box>
        ),
      },
      {
        Header: "Customer",
        accessor: ({ user }: Order): string =>
          `${user.firstName} ${user.lastName}.`,
        width: 100,
        Cell: ({ value, row: { id } }: CellProps<Order, string>) => (
          <span
            style={{
              alignSelf: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            data-cy={`customer-${id}`}
          >
            {value}
          </span>
        ),
      },
      // {
      //   Header: "",
      //   id: "items",
      //   accessor: (order) => order,
      //   maxWidth: 80,
      //   align: "right",
      //   Cell: ({
      //     value: { treatCount, drinkCount, entreeCount },
      //     row: { id },
      //   }: CellProps<Order, Order>) => (
      //     <Box
      //       display="flex"
      //       height="100%"
      //       justifyContent="space-between"
      //       alignItems="center"
      //       px="1rem"
      //     >
      //       <OrderTypeCount
      //         className={`treat-count-${id}`}
      //         icon={<TreatIcon />}
      //         count={treatCount}
      //       />
      //       <OrderTypeCount
      //         className={`drink-count-${id}`}
      //         icon={<DrinkIcon />}
      //         count={drinkCount}
      //       />
      //       <OrderTypeCount
      //         className={`entree-count-${id}`}
      //         icon={<EntreeIcon />}
      //         count={entreeCount}
      //       />
      //     </Box>
      //   ),
      // },
      {
        Header: "Order status",
        accessor: (order) => order,
        align: "center",
        Cell: ({ value, row: { id } }: CellProps<Order, Order>) => (
          <Status
            data-cy={`status-${id}`}
            viewType={ViewType.RESTAURANT}
            status={value.status}
            timestamps={value.timestamps}
            statusToDisplayName={mapStatusToDisplayNameRestaurantView}
          />
        ),
      },
      {
        Header: "Destination",
        accessor: ({ fulfillment }) => fulfillment.destination,
        align: "center",
        width: 100,
        Cell: ({
          value,
          row: { id },
        }: CellProps<Order, OrderFulfillmentDestination>) => (
          <Destination data-cy={`destination-${id}`} destination={value} />
        ),
      },
      {
        Header: "Locator",
        accessor: (order) => order,
        Cell: ({ value, row: { id } }: CellProps<Order, Order>) => (
          <Locator data-cy={`locator-${id}`} fulfillment={value.fulfillment} />
        ),
      },
    ];
  }, [ordersTab]);

  let content = (
    <OrdersTable
      orders={
        ordersTab === OrdersTab.CURRENT
          ? [
              ...filteredPinnedOrdersNotInOrders,
              ...filteredPinnedOrders,
              ...filteredSortedOrders,
            ]
          : filteredSortedOrders
      }
      columns={columns}
      completeOrdersOnSwipe={true}
    />
  );

  if (orders.error) {
    content = <div>Error {orders.error.toString()}</div>;
  } else if (orders.loading || !orders.orders) {
    content = (
      <Box role="progressbar">
        <StyledChickenSpinner />
      </Box>
    );
  }

  return (
    <>
      <MenuSidebar
        categories={
          ordersTab === OrdersTab.COMPLETED
            ? [FilterCategory.DESTINATION]
            : [FilterCategory.DESTINATION, FilterCategory.STATUS]
        }
        viewType={ViewType.RESTAURANT}
        visible={sidebar.isOpen && sidebar.type === SidebarType.MENU}
        onCloseClicked={() => {
          dispatch({ type: CLOSE_SIDEBAR });
        }}
      />
      <SettingsSidebar
        viewType={ViewType.RESTAURANT}
        visible={sidebar.isOpen && sidebar.type === SidebarType.SETTINGS}
        onBackClicked={() => {
          dispatch({ type: OPEN_SIDEBAR, sidebarType: SidebarType.MENU });
        }}
      />
      {content}
    </>
  );
}

// Orders shown in current queue should have operator specified filters,
// filter out Completed and Cancelled, filter other statuses by time, and sort by status
export function filterOrdersForRestaurantCurrentQueueView(
  orders: Order[],
  filters: FilterConfig,
  autoClearTimeouts: AutoClearTimeouts
): Order[] {
  return filterOrdersByConfig(orders, filters)
    .filter(filterOutStatus(OrderStatus.Complete))
    .filter(filterOutStatus(OrderStatus.Cancelled))
    .filter(filterByTimeInStatus(autoClearTimeouts));
}

// Orders shown in Complete should only show Completed rows and Ready rows that have timed out,
// should use all the other filters the operator specified,
// should filter out orders that are not today, and sort by the completed timestamp in desc order
export function filterOrdersForRestaurantCompletedView(
  orders: Order[],
  filterConfig: FilterConfig,
  autoClearTimeouts: AutoClearTimeouts
): Order[] {
  return filterOrdersByConfig(orders, {
    ...filterConfig,
    [FilterCategory.STATUS]: [OrderStatus.Complete, OrderStatus.Ready],
  })
    .filter(filterCompletedOlderThanToday)
    .filter((order) => {
      return (
        order.status === OrderStatus.Complete ||
        !filterByTimeInStatus(autoClearTimeouts)(order)
      );
    });
}

export function filterOrdersBySearch(orders: Order[], text: string): Order[] {
  return orders.filter((order) => {
    return `${order.user.firstName} ${order.user.lastName}`
      .toLowerCase()
      .includes(text.toLowerCase());
  });
}

export const StyledChickenSpinner = styled(ChickenSpinner)`
  width: 100%;
  height: 11rem;
  margin-top: 6.25rem;
`;
