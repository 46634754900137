import { useEffect } from "react";
import { OrdersTab } from "../models/orders-tab";
import { ViewType } from "../models/view";
import { logAmplitudeEvent } from "./amplitude";
import { LogEvent } from "./types";

interface EventData {
  viewType: ViewType;
  tab?: OrdersTab;
  locationName?: string;
  locationNumber?: string;
  destinationFilters?: string[];
  statusFilters?: string[];
  sortType?: string;
  bumpTimeout?: number;
}

export const usePageViewAnalytic = (
  event: LogEvent,
  {
    viewType,
    tab,
    locationName,
    locationNumber,
    destinationFilters = [],
    statusFilters = [],
    sortType,
    bumpTimeout,
  }: EventData
) => {
  useEffect(() => {
    if (locationName && locationNumber) {
      logAmplitudeEvent(event, {
        VIEW_TYPE: viewType,
        TAB: tab,
        LOCATION_NAME: locationName,
        LOCATION_NUMBER: locationNumber,
        DESTINATION_FILTERS: destinationFilters,
        STATUS_FILTERS: statusFilters,
        SORT_TYPE: sortType,
        BUMP_TIMEOUT: bumpTimeout,
      });
    }
  }, [event, viewType, tab, locationNumber]);
};
