import { FilterCategory } from "../models/filter";
import { Location } from "../models/location";
import { Order, OrderStatus } from "../models/order";
import { SidebarType } from "../models/sidebar";
import { SortType } from "../models/sort";
import { User } from "../models/user";
import { ViewType } from "../models/view";

export interface State {
  selectedLocation?: Location;
  locations: Location[];
  filters: ViewFilterConfig;
  orders: OrderState;
  pinnedOrders: Order[];
  sidebar: SidebarState;
  user?: User;
  viewType: ViewType;
  settings: SettingsState;
}

export type ViewFilterConfig = {
  [key in ViewType]: FilterConfig;
};

export type FilterConfig = {
  [key in FilterCategory]?: string[];
};

export interface OrderState {
  loading: boolean;
  error?: Error;
  orders: Order[];
}

export interface SidebarState {
  isOpen: boolean;
  type?: SidebarType;
}

export interface SettingsState {
  customerViewType: CustomerViewType;
  customerViewTableCount: number;
  customerViewFontSize: number;
  customerViewOrdersPerTable: number;
  customerViewPagingIntervalInMS: number;
  orderRefetchIntervalInMS: number;
  autoClearTimeouts: AutoClearTimeouts;
  sortType: SortType;
}

export interface AutoClearTimeouts {
  [OrderStatus.Ready]: number;
  [OrderStatus.CheckIn]: number;
  [OrderStatus.Error]: number;
}

export enum CustomerViewType {
  COLUMN = "COLUMN",
  GRID = "GRID",
}

export const initialState: State = {
  selectedLocation: undefined,
  locations: [],
  filters: {
    [ViewType.CUSTOMER]: {},
    [ViewType.RESTAURANT]: {},
  },
  orders: {
    loading: true,
    orders: [],
  },
  pinnedOrders: [],
  sidebar: {
    isOpen: false,
    type: SidebarType.MENU,
  },
  viewType: ViewType.RESTAURANT,
  settings: {
    customerViewType: CustomerViewType.COLUMN,
    customerViewTableCount: 1,
    customerViewFontSize: 1,
    customerViewOrdersPerTable: 10,
    customerViewPagingIntervalInMS: 5000,
    orderRefetchIntervalInMS: 60000,
    autoClearTimeouts: {
      [OrderStatus.Ready]: 5,
      [OrderStatus.CheckIn]: 15,
      [OrderStatus.Error]: 15,
    },
    sortType: SortType.OLDEST_AT_TOP,
  },
};
