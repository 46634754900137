import React, { useRef, useState } from "react";
import Draggable, { DraggableData } from "react-draggable";
import { Row } from "react-table";
import { TableRow } from "./table-row";

export interface DragOptions<T extends object> {
  /**
   * A number 0 - 1 that indicates at what threshold percentage the drag should trigger the action
   */
  threshold: number;
  /**
   * Callback action to call if the drag threshold is passed
   */
  onSwiped: (item: T) => void;
  /**
   * Content to display "behind" the row as it is dragged away. The width of the content is directly modified
   * as the row is dragged.
   */
  backgroundContent?: JSX.Element;
}

export interface DraggableTableRowProps<T extends object> {
  /**
   * Row of data to render
   */
  row: Row<T>;

  /**
   * Options for behavior when dragging row from right to left. If omitted, no swiping will occur
   */
  dragLeft?: DragOptions<T>;

  /**
   * Options for behavior when dragging row from left to right. If omitted, no swiping will occur
   */
  dragRight?: DragOptions<T>;
}

export function DraggableTableRow<T extends object>(
  props: DraggableTableRowProps<T>
) {
  const { dragLeft, dragRight, row } = props;

  const rowRef = useRef<HTMLElement>();
  // Boolean for whether the row is being dragged
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // Boolean for whether the drag/swipe crossed the threshold and triggered the action
  const [dragTriggered, setDragTriggered] = useState<boolean>(false);
  // Amount that the row has been dragged. Used for the css transform to determine location
  const [dragPos, setDragPos] = useState<number>(0);
  // Boolean for whether the user is scrolling
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  // Amount the user has scrolled. Used to help determine if the user is scrolling or dragging
  const [scrollPos, setScrollPos] = useState<number>(0);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        cursor: isDragging ? "grabbing" : "grab",
      }}
    >
      {dragRight != null && dragRight.backgroundContent != null && (
        <div
          className="tr-background-left"
          style={{
            position: "absolute",
            left: 0,
            width: dragPos > 0 ? dragPos : 0,
            height: "100%",
            overflow: "hidden",
            textOverflow: "hidden",
            transition: !isDragging ? "0.5s" : undefined,
          }}
        >
          {dragRight.backgroundContent}
        </div>
      )}
      <Draggable
        axis="x" // Only allow dragging horizontally
        // Restriction on how far the row is allowed to be dragged.
        // 0 = not draggable
        // undefined = no restriction
        bounds={{
          right: isScrolling ? 0 : dragRight == null ? 0 : undefined,
          left: isScrolling ? 0 : dragLeft == null ? 0 : undefined,
        }}
        // Control the position programatically. Go back to 0 if action was not triggered, go off page if action is triggered
        position={{
          x: dragTriggered && rowRef.current != null ? dragPos || 0 : 0,
          y: 0,
        }}
        nodeRef={rowRef as React.RefObject<HTMLElement>}
        onDrag={(_, d: DraggableData) => {
          // If haven't determined if scrolling or dragging yet
          if (!isDragging && !isScrolling) {
            const deltaX = Math.abs(d.x);
            const newScrollPos = scrollPos + Math.abs(d.y);

            // Update scroll position
            setScrollPos(newScrollPos);

            // Perform the dragging and scrolling
            window.scrollBy(0, -d.y);
            setDragPos(d.x);

            // If we've dragged more than 30px and more than we've swiped
            // Set dragging to true
            if (deltaX > 30 && deltaX > newScrollPos) {
              setIsDragging(true);
            }
            // Else, if we've swiped more than 30px and more than we've dragged
            // set swiping to true and reset the drag position
            else if (newScrollPos > 30 && newScrollPos > deltaX) {
              setIsScrolling(true);
              setDragPos(0);
            }

            return;
          }

          if (isScrolling) {
            window.scrollBy(0, -d.y);
          }
          if (isDragging) {
            setDragPos(d.x);
          }
        }}
        onStop={(_, d: DraggableData) => {
          setIsDragging(false);
          setIsScrolling(false);
          setScrollPos(0);
          setDragPos(0);

          if (
            rowRef.current != null &&
            dragLeft != null &&
            -d.x / rowRef.current.scrollWidth > dragLeft.threshold
          ) {
            setDragTriggered(true);
            setDragPos(-rowRef.current.scrollWidth);
            setTimeout(() => {
              dragLeft.onSwiped(row.original);
            }, 500);
          }

          if (
            rowRef.current != null &&
            dragRight != null &&
            d.x / rowRef.current.scrollWidth > dragRight.threshold
          ) {
            setDragTriggered(true);
            setDragPos(rowRef.current.scrollWidth);
            setTimeout(() => {
              dragRight.onSwiped(row.original);
            }, 500);
          }
        }}
      >
        <div
          className="tr-draggable-container"
          style={{
            width: "100%",
            transition: !isDragging ? "0.5s" : undefined,
          }}
          ref={(el) => {
            if (el != null) {
              rowRef.current = el;
            }
          }}
        >
          <TableRow row={row}></TableRow>
        </div>
      </Draggable>
      {dragLeft != null && dragLeft.backgroundContent != null && (
        <div
          className="tr-background-right"
          style={{
            position: "absolute",
            right: 0,
            width: dragPos < 0 ? -dragPos : "3.5rem",
            height: "100%",
            overflow: "hidden",
            textOverflow: "hidden",
            transition: !isDragging ? "0.5s" : undefined,
          }}
        >
          {dragLeft.backgroundContent}
        </div>
      )}
    </div>
  );
}
