import classNames from "classnames";
import React, { FormEvent, Ref, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CaretDown } from "../../icons/caret-down.svg";
import { ReactComponent as CaretLeft } from "../../icons/caret-left.svg";
import { ReactComponent as CaretRight } from "../../icons/caret-right.svg";
import Input from "../input/Input";

export interface FilterDropDownProps {
  items: FilterDropDownItem[];
  itemPluralName?: string;
  pageSize: number;
  selectedItem: string;
  onSelectItem: (id: string) => void;
}

export interface FilterDropDownItem {
  id: string;
  displayName: string;
}

export function FilterDropDown({
  items,
  pageSize,
  selectedItem,
  onSelectItem,
  itemPluralName,
}: FilterDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [renderedItems, setRenderedItems] = useState<FilterDropDownItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<FilterDropDownItem[]>([]);
  const [filterText, setFilterText] = useState("");
  const dropDownRef = useRef<HTMLDivElement>();
  const filterInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setFilterText("");
    setPageNum(0);
    setFilteredItems(items);
    if (isOpen) {
      filterInputRef.current?.focus();
    }
  }, [items, isOpen]);

  useEffect(() => {
    const pageStart = pageSize * pageNum;

    setRenderedItems(filteredItems.slice(pageStart, pageStart + pageSize));
  }, [filteredItems, pageSize, pageNum]);

  useEffect(() => {
    function handleEscPress(e: KeyboardEvent) {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("keydown", handleEscPress, false);

      return function cleanup() {
        document.removeEventListener("keydown", handleEscPress, false);
      };
    }
  }, [isOpen]);

  function handleFilterTextChange(event: FormEvent<HTMLInputElement>) {
    const filter = event.currentTarget.value;
    setFilterText(filter);

    const newFilteredItems = items.filter(
      (item) =>
        item.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
    setFilteredItems(newFilteredItems);
    setPageNum(0);
  }

  const startIndex = filteredItems.length > 0 ? 1 + pageSize * pageNum : 0;
  let endIndex = pageSize * (pageNum + 1);
  if (endIndex > filteredItems.length) {
    endIndex = filteredItems.length;
  }

  function highlightFilteredText(text: string) {
    if (filterText === "") {
      return text;
    }

    const parts = text.split(new RegExp(`(${filterText})`, "gi"));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === filterText.toLowerCase()
                ? { color: "#DD0031" }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  }

  function onInputFormSubmit(e: FormEvent) {
    e.preventDefault();
    if (filteredItems.length > 0) {
      setIsOpen(false);
      onSelectItem(filteredItems[0].id);
    }
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <div>
      <DropDownHeader
        data-cy={`filter-dropdown`}
        className={classNames("dropDownHeader", {
          expandable: items.length > 1,
        })}
        ref={dropDownRef as Ref<HTMLDivElement>}
        onClick={() => {
          if (items.length > 1) {
            setIsOpen(true);
          }
        }}
      >
        <DropDownHeaderTitle>{selectedItem}</DropDownHeaderTitle>
        {items.length > 1 && (
          <DropDownHeaderButton>
            <CaretDown fill="#004f71" height={"2rem"} />
          </DropDownHeaderButton>
        )}
      </DropDownHeader>
      {isOpen && (
        <>
          <FullscreenOverlay
            className="overlay"
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <DropDown
            className="dropdown"
            style={{
              top: dropDownRef.current?.getBoundingClientRect().bottom,
              right:
                window.innerWidth -
                (dropDownRef.current?.getBoundingClientRect().right as number),
            }}
          >
            <form onSubmitCapture={onInputFormSubmit}>
              <Input
                autoFocus
                data-cy={`filter-dropdown-input`}
                type="text"
                placeholder={`Search ${items.length} ${
                  itemPluralName || "Items"
                }`}
                value={filterText}
                onChange={handleFilterTextChange}
                ref={filterInputRef as Ref<HTMLInputElement>}
              />
            </form>
            <List>
              {renderedItems.map((item) => (
                <li key={item.id}>
                  <ListItemButton
                    data-cy={`filter-dropdown-item-${item.id}`}
                    onClick={() => {
                      setIsOpen(false);
                      onSelectItem(item.id);
                    }}
                  >
                    {highlightFilteredText(item.displayName)}
                  </ListItemButton>
                </li>
              ))}
            </List>
            <PageControls>
              <PageButton
                data-cy={`filter-dropdown-pageleft`}
                className="pageLeft"
                onClick={() => {
                  setPageNum(pageNum - 1);
                }}
                disabled={pageNum === 0}
              >
                <CaretLeft
                  fill={pageNum !== 0 ? "#004f71" : "#DD0031"}
                  height={"1.5rem"}
                />
              </PageButton>

              <span>
                {startIndex} - {endIndex} of {filteredItems.length}
              </span>

              <PageButton
                data-cy={`filter-dropdown-pageRight`}
                className="pageRight"
                onClick={() => {
                  setPageNum(pageNum + 1);
                }}
                disabled={(pageNum + 1) * pageSize >= filteredItems.length}
              >
                <CaretRight
                  fill={
                    (pageNum + 1) * pageSize <= filteredItems.length
                      ? "#004f71"
                      : "#DD0031"
                  }
                  height={"1.5rem"}
                />
              </PageButton>
            </PageControls>
          </DropDown>
        </>
      )}
    </div>
  );
}

const DropDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  &.expandable {
    cursor: pointer;
  }
`;

const DropDownHeaderTitle = styled.span`
  color: ${(props) => props.theme?.colors?.tertiary};
  font-weight: 700;
`;

const DropDownHeaderButton = styled.button`
  border: none;
  overflow: hidden;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;
`;

const FullscreenOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const DropDown = styled.div`
  position: fixed;
  width: 19rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-content: flex-end;
  background-color: ${(props) => props.theme?.colors?.background};

  z-index: 999;
`;

const List = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  height: 80%;
`;

const ListItemButton = styled.button`
  border: none;
  overflow: hidden;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme?.colors?.tertiary};
  font-weight: bold;
  font-size: 1.125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0.25rem;
`;

const PageControls = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme?.colors?.tertiary};
  font-weight: 700;
  font-size: 1.125rem;
`;

const PageButton = styled.button`
  padding: 0;
  border: none;
  background: none;

  :enabled {
    cursor: pointer;
  }
`;
