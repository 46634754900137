import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { getEnvConfig } from "../config/config";
import { oktaAuth } from "../okta/okta";

export function createApolloClient() {
  // Create an http link:
  const httpLink = createHttpLink({
    uri: getEnvConfig().GraphQL.httpEndpoint,
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: getEnvConfig().GraphQL.websocketEndpoint,
    options: {
      reconnect: true,
    },
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from Okta
    const token = await oktaAuth.getAccessToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
  });
}
