import OktaAuth from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { getEnvConfig } from "../lib/config/config";
import { Location } from "../models/location";
import { UPDATE_LOCATION_LIST } from "../reducers/actions";
import { useRadarDispatch, useRadarState } from "../reducers/reducers";

const ADMIN_LOCATION = "00000";

export const useLocationFetch = () => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useRadarDispatch();
  const { user } = useRadarState();

  useEffect(() => {
    const getLocations = async () => {
      if (user == null) {
        return;
      }

      const allLocations = await fetchAllLocations(oktaAuth);
      let filteredLocations = allLocations;

      const locationNumbers = user["cfa-locations"] || [];
      if (locationNumbers.indexOf(ADMIN_LOCATION) === -1) {
        filteredLocations = locationNumbers
          .map((locationNumber) =>
            allLocations.find(
              (location) => location.locationNumber === locationNumber
            )
          )
          .filter((location) => location != null) as Location[];
      }

      dispatch({ type: UPDATE_LOCATION_LIST, locations: filteredLocations });
    };
    getLocations();
  }, [oktaAuth, dispatch, user]);
};

async function fetchAllLocations(oktaAuth: OktaAuth): Promise<Location[]> {
  const token = await oktaAuth.getAccessToken();
  const httpEndpoint = `${
    getEnvConfig().Locations.baseUrl
  }/locations/4.0/locationSummary`;

  const response = await fetch(httpEndpoint, {
    headers: new Headers({
      Authorization: token ? `JWTBearer ${token}` : "",
    }),
  });
  return response.json();
}
