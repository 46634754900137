import moment from "moment";
import { Order, OrderStatus } from "../../models/order";

export function sortOrdersByRelevantTimestamp(
  orders: Order[],
  reverse: boolean
): Order[] {
  return orders.sort((f, s) => {
    const statusOrder = [
      OrderStatus.Ready,
      OrderStatus.CheckIn,
      OrderStatus.Submit,
      OrderStatus.Error,
    ];

    if (f.status === s.status) {
      const first = reverse ? s : f;
      const second = reverse ? f : s;

      switch (first.status) {
        case OrderStatus.Submit:
          return moment(second.timestamps.submit).diff(first.timestamps.submit);
        case OrderStatus.CheckIn:
          return moment(second.timestamps.checkIn).diff(
            first.timestamps.checkIn
          );
        case OrderStatus.Ready:
          return moment(second.timestamps.ready).diff(first.timestamps.ready);
        case OrderStatus.Error:
          return moment(second.timestamps.error).diff(first.timestamps.error);
      }
    }

    return statusOrder.indexOf(f.status) - statusOrder.indexOf(s.status);
  });
}

// Modified to change the order of the sort on completed orders
export function sortOrdersByCompletedTimestamp(
  orders: Order[],
  reverse: boolean
): Order[] {
  return orders.sort((f, s) => {
    const statusOrder = [OrderStatus.Complete, OrderStatus.Ready];

    if (f.status === s.status) {
      const first = reverse ? s : f;
      const second = reverse ? f : s;

      switch (first.status) {
        case OrderStatus.Complete:
          return moment(second.timestamps.complete).diff(
            first.timestamps.complete
          );
        case OrderStatus.Ready:
          return moment(second.timestamps.ready).diff(first.timestamps.ready);
      }
    }
    return statusOrder.indexOf(f.status) - statusOrder.indexOf(s.status);
  });
}
