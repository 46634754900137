import React from "react";
import { useTheme } from "styled-components";
import { Box } from "../../../components/base/box/Box";

export function CustomerViewFooter() {
  const theme = useTheme();

  return (
    <Box
      padding="0.5rem"
      backgroundColor={theme.colors.primary}
      color="white"
      width="100%"
      textAlign="center"
      className="customer-view-footer"
    >
      <Box>
        Order sitting in "Submitted" status? You may need to check-in your order
        on your phone.
      </Box>
      <Box>
        Please see a team member if you have problems checking in your order.
      </Box>
    </Box>
  );
}
