import { useMutation } from "@apollo/client";
import * as React from "react";
import { Column, Row } from "react-table";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { Order, OrderStatus } from "../../models/order";
import { ORDER_COMPLETE_MUTATION } from "../../queries/order";
import { Box } from "../base/box/Box";
import { DraggableTableRow } from "../base/table/draggable-table-row";
import { Table } from "../base/table/table";
import { TableRow } from "../base/table/table-row";
import { ReactComponent as SwipeLeft } from "../icons/swipe-left.svg";
import { NoContent } from "./no-content";

export interface OrdersTableProps {
  orders: Order[];
  columns: Column<Order>[];
  completeOrdersOnSwipe?: boolean;
}

export function OrdersTable({
  columns,
  orders,
  completeOrdersOnSwipe,
}: OrdersTableProps) {
  const [completeOrder] = useMutation(ORDER_COMPLETE_MUTATION);

  async function onCompleteOrder(order: Order) {
    await completeOrder({
      variables: { orderID: order.id },
    });
    logAmplitudeEvent(LogEvent.CompleteOrder, {
      ORDER_ID: order.id,
      ORDER_STATUS: OrderStatus.Complete,
      LOCATION_NUMBER: order.locationNumber,
      CUSTOMER_NAME: `${order?.user?.firstName} ${order?.user?.lastName}`,
      DESTINATION: order?.fulfillment?.destination,
      ZONES_DINE_IN: order?.fulfillment?.zones?.dineIn,
      ZONES_FULFILLMENT_DETAILS:
        order?.fulfillment?.zones?.kpsFulfillmentDetails,
      ZONES_RADIUS: order?.fulfillment?.zones?.radius,
      VEHICLE_ID: order?.fulfillment?.vehicle?.id,
      VEHICLE_MAKE: order?.fulfillment?.vehicle?.make,
      VEHICLE_MODEL: order?.fulfillment?.vehicle.model,
      VEHICLE_COLOR: order?.fulfillment?.vehicle.color,
    });
  }

  return (
    <Box width="100%">
      <Table
        applyStickyHeader={true}
        columns={columns}
        data={orders}
        rowIdAccessor={(order: Order) => order.id}
        noContent={
          <NoContent data-cy={`noContent`}>
            No orders at the moment, but we still think you're awesome{" "}
            <span aria-label="hands raised" role="img">
              🙌
            </span>
          </NoContent>
        }
        renderRow={(row: Row<Order>) => {
          if (
            row.original.status === OrderStatus.Ready &&
            completeOrdersOnSwipe
          ) {
            return (
              <DraggableTableRow
                row={row}
                dragLeft={{
                  threshold: 0.3,
                  onSwiped: (order) => {
                    onCompleteOrder(order);
                  },
                  backgroundContent: (
                    <div
                      style={{
                        height: "100%",
                        backgroundImage:
                          "linear-gradient(to right, transparent, #34a1b7)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          position: "absolute",
                          right: "0.5rem",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <span
                          style={{
                            paddingRight: "2rem",
                            fontFamily: "Apercu, Arial, sans-serif",
                            color: "#ffffff",
                          }}
                        >
                          Completed
                        </span>
                        <SwipeLeft fill="#ffffff" />
                      </div>
                    </div>
                  ),
                }}
              />
            );
          } else {
            return <TableRow row={row} />;
          }
        }}
      />
    </Box>
  );
}
