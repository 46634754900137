import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  color: ${(props) => props.theme?.colors?.text};
  font-weight: bold;
  border-radius: 5px;
  border-color: #c7c7cd;
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgNDggNDgiIHdpZHRoPSI0OCIgZmlsbD0iI0REMDAzMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTQuODMgMTYuNDJsOS4xNyA5LjE3IDkuMTctOS4xNyAyLjgzIDIuODMtMTIgMTItMTItMTJ6Ii8+CiAgPHBhdGggZD0iTTAtLjc1aDQ4djQ4aC00OHoiIGZpbGw9Im5vbmUiLz4KPC9zdmc+)
    no-repeat 100% 50%;

  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

interface DropDownProps<T> {
  className?: string;
  defaultValue?: T;
  isDisabled?: boolean;
  onChange: (id: T) => void;
  options: { value: string; text: string }[];
}

export const DropDown = <T,>({
  className,
  defaultValue,
  isDisabled,
  onChange,
  options,
}: DropDownProps<T>) => {
  return (
    <Container className={className}>
      <Select
        value={defaultValue as unknown as string}
        disabled={isDisabled}
        onChange={(e) => {
          onChange(e.target.value as unknown as T);
        }}
      >
        {options.map((option, index) => (
          <option key={option.value + index} value={option.value}>
            {option.text}
          </option>
        ))}
      </Select>
    </Container>
  );
};
