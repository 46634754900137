export type OktaCfg = { issuer: string; clientId: string; logoutUri: string };
export type EnvConfig = {
  readonly Okta: OktaCfg;
  readonly GraphQL: {
    readonly httpEndpoint: string;
    readonly websocketEndpoint: string;
  };
  readonly Locations: {
    readonly baseUrl: string;
    readonly allowedCustomerFacingView: string[];
  };
  readonly Feedback: {
    readonly httpEndpoint: string;
  };
};

export type Config = {
  useLocalServer: boolean;
  env: { local: EnvConfig; dev: EnvConfig; qa: EnvConfig; prod: EnvConfig };
};

export enum ENVS {
  LOCAL = "local",
  DEV = "dev",
  QA = "qa",
  PROD = "prod",
}

const locationsAllowedCustomerFacingView = [
  "00238",
  "00399",
  "00617",
  "03591",
  "03977",
  "04070",
  "04169",
  "04259",
  "04549",
  "04680",
  "04221",
  "04731",
  "04205",
  "03462",
  "03400",
  "02226",
  "04547",
  "00661",
  "01507",
  "04003",
  "02703",
  "04068",
  "04953",
  "03346",
  "00060",
  "03777",
  "01384",
  "04430",
  "04161",
  "03867",
  "04489",
  "03879",
  "04961",
  "03774",  
  "01484",
  "04572",
  "02072",
  "02281",
  "00722",
  "02177",
  "02932",
  "04960",
  "02871",
  "03375",
  "02841",
  "04143",
  "04953",
  "05035",
  "04006",
  "03449",
  "04240",
  "04313",
  "02201",
  "03430",
  "04491",
  "03705",
  "04085",
  "05191",
  "04346",
  "03441",
  "00009",
  "03661",
  "01162", 
  "05229",
];

// Locations that are labs and will only be enabled in non-prod envs
const testLocationsAllowedCustomerFacingView = ["00070", "02345", "03700"];

export const config: Config = {
  useLocalServer: !!process.env.REACT_APP_USE_LOCAL_SERVER,
  env: {
    local: {
      Okta: {
        issuer: "https://cfahome.oktapreview.com/oauth2/ausc6e4sj2fnPnQ670h7",
        clientId: "0oas2vsa46qBk2EZz0h7",
        logoutUri: "https://dev.cfahome.com",
      },
      GraphQL: {
        httpEndpoint: "http://localhost:8080/query",
        websocketEndpoint: "ws://localhost:8080/query",
      },
      Locations: {
        baseUrl: "https://location.api.dev.crndev.chick-fil-a.com",
        allowedCustomerFacingView: locationsAllowedCustomerFacingView.concat(
          testLocationsAllowedCustomerFacingView
        ),
      },
      Feedback: {
        httpEndpoint:
          "https://docs.google.com/forms/d/e/1FAIpQLSc3n-cyP4Ny5o6uSn0weERHL_df8B3Hpk8O46MBk3CPXvwnIA/viewform?usp=sf_link",
      },
    },
    dev: {
      Okta: {
        issuer: "https://cfahome.oktapreview.com/oauth2/ausc6e4sj2fnPnQ670h7",
        clientId: "0oas2vsa46qBk2EZz0h7",
        logoutUri: "https://dev.cfahome.com",
      },
      GraphQL: {
        httpEndpoint:
          "https://vp-radar-api.dxe.dev.crndev.chick-fil-a.com/query",
        websocketEndpoint:
          "wss://vp-radar-api.dxe.dev.crndev.chick-fil-a.com/query",
      },
      Locations: {
        baseUrl: "https://location.api.dev.crndev.chick-fil-a.com",
        allowedCustomerFacingView: locationsAllowedCustomerFacingView.concat(
          testLocationsAllowedCustomerFacingView
        ),
      },
      Feedback: {
        httpEndpoint:
          "https://docs.google.com/forms/d/e/1FAIpQLSc3n-cyP4Ny5o6uSn0weERHL_df8B3Hpk8O46MBk3CPXvwnIA/viewform?usp=sf_link",
      },
    },
    qa: {
      Okta: {
        issuer: "https://cfahome.oktapreview.com/oauth2/auscjsakz3PaAP82K0h7",
        clientId: "0oas2vsa46qBk2EZz0h7",
        logoutUri: "https://dev.cfahome.com",
      },
      GraphQL: {
        httpEndpoint:
          "https://vp-radar-api.dxe.qa.crndev.chick-fil-a.com/query",
        websocketEndpoint:
          "wss://vp-radar-api.dxe.qa.crndev.chick-fil-a.com/query",
      },
      Locations: {
        baseUrl: "https://location.api.qa.crndev.chick-fil-a.com",
        allowedCustomerFacingView: locationsAllowedCustomerFacingView.concat(
          testLocationsAllowedCustomerFacingView
        ),
      },
      Feedback: {
        httpEndpoint:
          "https://docs.google.com/forms/d/e/1FAIpQLSc3n-cyP4Ny5o6uSn0weERHL_df8B3Hpk8O46MBk3CPXvwnIA/viewform?usp=sf_link",
      },
    },
    prod: {
      Okta: {
        issuer: "https://cfahome.okta.com/oauth2/aus4i6zex3F52d4rn1t7",
        clientId: "0oaehw4unzw6g0Jy71t7",
        logoutUri: "https://www.cfahome.com",
      },
      GraphQL: {
        httpEndpoint: "https://vp-radar-api.dxe.my.chick-fil-a.com/query",
        websocketEndpoint: "wss://vp-radar-api.dxe.my.chick-fil-a.com/query",
      },
      Locations: {
        baseUrl: "https://location.api.my.chick-fil-a.com",
        allowedCustomerFacingView: locationsAllowedCustomerFacingView,
      },
      Feedback: {
        httpEndpoint:
          "https://docs.google.com/forms/d/e/1FAIpQLSc3n-cyP4Ny5o6uSn0weERHL_df8B3Hpk8O46MBk3CPXvwnIA/viewform?usp=sf_link",
      },
    },
  },
};

export const getEnv = (): ENVS => {
  const hostRegex =
    /viewpoint-radar\.dxe\.(dev|qa|my)(\.crndev)?\.chick-fil-a\.com/;
  const url = window.location.hostname;
  const matched = url.match(hostRegex);
  const env = matched && matched.length && matched.length > 0 && matched[1];
  switch (env) {
    case "dev":
      return ENVS.DEV;
    case "qa":
      return ENVS.QA;
    case "my":
      return ENVS.PROD;
    default:
      return config.useLocalServer ? ENVS.LOCAL : ENVS.DEV;
  }
};

export const getEnvConfig = (): EnvConfig => {
  return config.env[getEnv()];
};

export const isProd = getEnv() === ENVS.PROD;
