import React from "react";
import styled from "styled-components";
import { Icon } from "../icon/icon";

export interface HeaderIconProps {
  onClick: () => void;
  title: string;
  iconImageSrc: string;
}

export const HeaderIcon = (props: HeaderIconProps) => {
  return (
    <StyledHeaderIcon {...props} onClick={props.onClick}>
      <Icon imageSrc={props.iconImageSrc} alt={props.title} />
      <StyledHeaderIconText>{props.title}</StyledHeaderIconText>
    </StyledHeaderIcon>
  );
};

const StyledHeaderIcon = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const StyledHeaderIconText = styled.span`
  font-size: 0.625rem;
  padding-top: 0.25rem;
  color: ${(props) => props.theme?.colors?.tertiary};
`;
