import React from "react";
import styled from "styled-components";
import { HeaderIcon } from "../../../components/base/header-icon/header-icon";
import {
  HeaderText,
  HeaderTextSize,
  HeaderTextVariant,
} from "../../../components/base/header-text/header-text";
import { Icon } from "../../../components/base/icon/icon";
import { SidebarType } from "../../../models/sidebar";
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";

export function CustomerViewHeader() {
  const dispatch = useRadarDispatch();
  const { sidebar, selectedLocation } = useRadarState();

  return (
    <>
      <HeaderGroup>
        <StyledLogo>
          <Icon imageSrc="/icons/cfa-logo.svg" alt="Chick-fil-A Circle Logo" />
        </StyledLogo>
      </HeaderGroup>
      <HeaderMiddle>
        <HeaderText
          variant={HeaderTextVariant.SECONDARY}
          size={HeaderTextSize.SMALL}
        >
          Welcome to Mobile Pick-Up at {selectedLocation?.name} Chick-fil-A®
        </HeaderText>
      </HeaderMiddle>
      <HeaderRight>
        <HeaderIcon
          data-cy="menu-sidebar"
          onClick={() => {
            if (sidebar.isOpen) {
              dispatch({ type: CLOSE_SIDEBAR });
            } else {
              dispatch({
                type: OPEN_SIDEBAR,
                sidebarType: SidebarType.MENU,
              });
            }
          }}
          iconImageSrc="/icons/menu.svg"
          title="Menu"
        />
      </HeaderRight>
    </>
  );
}

const HeaderGroup = styled.div`
  display: flex;
`;

const StyledLogo = styled.div`
  height: 2.5rem;
`;

const HeaderMiddle = styled(HeaderGroup)`
  justify-content: space-around;
`;

const HeaderRight = styled(HeaderGroup)`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
`;
