import React from "react";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { SET_FONT_SIZE } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { IncrementInput } from "../increment-input/IncrementInput";

export function FontSizeSetting() {
  const dispatch = useRadarDispatch();
  const {
    settings: { customerViewFontSize = 1 },
  } = useRadarState();

  function onDecrement() {
    if (customerViewFontSize > 1) {
      dispatch({
        type: SET_FONT_SIZE,
        fontSize: Number((customerViewFontSize - 0.1).toFixed(1)),
      });
      logAmplitudeEvent(LogEvent.UpdateFontSize, {
        TYPE: "DECREMENT",
        FONT_SIZE: Number((customerViewFontSize - 0.1).toFixed(1)),
      });
    }
  }
  function onIncrement() {
    if (customerViewFontSize < 2) {
      dispatch({
        type: SET_FONT_SIZE,
        fontSize: Number((customerViewFontSize + 0.1).toFixed(1)),
      });
      logAmplitudeEvent(LogEvent.UpdateFontSize, {
        TYPE: "INCREMENT",
        FONT_SIZE: Number((customerViewFontSize + 0.1).toFixed(1)),
      });
    }
  }

  return (
    <IncrementInput
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      text={customerViewFontSize}
    />
  );
}
