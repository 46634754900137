import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Tag, TagProps } from "./Tag";

interface Props extends TagProps {
  defaultTimer?: number;
  timerWarningInMinutes?: number;
}

export function TimerTag({
  defaultTimer = 0,
  timerWarningInMinutes,
  ...props
}: Props) {
  const theme = useTheme();
  const [timer, setTimer] = useState(defaultTimer);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const isOverTimerWarning =
    timerWarningInMinutes && timer >= timerWarningInMinutes * 60000;

  return (
    <Tag
      background={isOverTimerWarning ? theme.colors.error : ""}
      color={isOverTimerWarning ? "#fff" : ""}
      data-cy="status-timer"
      {...props}
    >
      {moment(timer).format("m:ss")}
    </Tag>
  );
}
