import classNames from "classnames";
import React from "react";
import styled from "styled-components";

export interface PagingIndicatorProps {
  totalPageCount: number;
  activePageNum: number;
  selectable?: boolean;
  shouldDisplayWithOnlyOnePage?: boolean;
  onClick?: (pageNum: number) => void;
}

export const PagingIndicator = (props: PagingIndicatorProps) => {
  if (props.totalPageCount === 1 && !props.shouldDisplayWithOnlyOnePage) {
    return <></>;
  }

  return (
    <>
      {Array(props.totalPageCount)
        .fill("")
        .map((_, index) => (
          <Dot
            key={index}
            data-testid="paging-indicator"
            data-cy={`paging-indicator-${index}`}
            className={classNames({
              active: index === props.activePageNum,
              selectable: props.selectable,
            })}
            onClick={() => {
              props.selectable && props.onClick != null && props.onClick(index);
            }}
          />
        ))}
    </>
  );
};

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.theme?.colors?.outline};
  border-radius: 50%;
  margin: 10px;
  &.active {
    background-color: ${(props) => props.theme?.colors?.primary};
  }
  &.selectable {
    cursor: pointer;
  }
`;
