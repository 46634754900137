import { FilterCategory } from "../models/filter";
import { Location } from "../models/location";
import { Order, OrderStatus } from "../models/order";
import { SidebarType } from "../models/sidebar";
import { SortType } from "../models/sort";
import { User } from "../models/user";
import { UserPreferences } from "../models/user-preferences";
import { ViewType } from "../models/view";
import { CustomerViewType, OrderState } from "./state";

export const UPDATE_LOCATION = "updateLocation";
export const UPDATE_LOCATION_LIST = "updateLocationList";
export const SET_FILTERS = "setFilters";
export const SET_SORT = "setSort";
export const SET_ORDERS = "setOrders";
export const UPDATE_ORDER = "updateOrder";
export const OPEN_SIDEBAR = "openSidebar";
export const CLOSE_SIDEBAR = "closeSidebar";
export const OPEN_MODAL = "openModal";
export const CLOSE_MODAL = "closeModal";
export const SET_USER = "setUser";
export const FETCH_USER_PREFS = "fetchUserPrefs";
export const LOAD_USER_PREFS = "loadUserPrefs";
export const SET_VIEW_TYPE = "setViewType";
export const SET_CUSTOMER_VIEW_TYPE = "setCustomerViewType";
export const SET_AUTO_CLEAR_TIMEOUTS = "setAutoClearTime";
export const SET_ORDERS_PER_TABLE = "setOrdersPerTable";
export const SET_FONT_SIZE = "setFontSize";
export const SET_TABLE_COUNT = "setTableCount";
export const ADD_PINNED_ORDER = "addPinnedOrder";
export const REMOVE_PINNED_ORDER = "removePinnedOrder";

export interface UpdateLocationAction {
  type: typeof UPDATE_LOCATION;
  location: Location;
}

export interface UpdateLocationListAction {
  type: typeof UPDATE_LOCATION_LIST;
  locations: Location[];
}

export interface SetFiltersAction {
  type: typeof SET_FILTERS;
  viewType: ViewType;
  filterCategory: FilterCategory;
  fields: string[];
}

export interface SetSortAction {
  type: typeof SET_SORT;
  sortType: SortType;
}

export interface SetOrdersAction {
  type: typeof SET_ORDERS;
  orders: OrderState;
}

export interface UpdateOrderAction {
  type: typeof UPDATE_ORDER;
  order: Order;
}

export interface OpenSidebarAction {
  type: typeof OPEN_SIDEBAR;
  sidebarType: SidebarType;
}

export interface CloseSidebarAction {
  type: typeof CLOSE_SIDEBAR;
}

export interface CloseModalAction {
  type: typeof CLOSE_MODAL;
}

export interface SetUserAction {
  type: typeof SET_USER;
  user: User;
}

export interface LoadUserPrefsAction {
  type: typeof LOAD_USER_PREFS;
  userPreferences: UserPreferences;
}

export interface SetAutoClearTimeouts {
  type: typeof SET_AUTO_CLEAR_TIMEOUTS;
  status: OrderStatus.Ready | OrderStatus.CheckIn | OrderStatus.Error;
  timeout: number;
}

export interface SetOrdersPerPage {
  type: typeof SET_ORDERS_PER_TABLE;
  numberOfOrders: number;
}

export interface SetFontSize {
  type: typeof SET_FONT_SIZE;
  fontSize: number;
}

export interface SetColumns {
  type: typeof SET_TABLE_COUNT;
  numberOfTables: number;
}

export interface SetViewType {
  type: typeof SET_VIEW_TYPE;
  viewType: ViewType;
}

export interface SetCustomerViewType {
  type: typeof SET_CUSTOMER_VIEW_TYPE;
  viewType: CustomerViewType;
}

export interface AddPinnedOrder {
  type: typeof ADD_PINNED_ORDER;
  order: Order;
}

export interface RemovePinnedOrder {
  type: typeof REMOVE_PINNED_ORDER;
  orderId: string;
}

export type Action =
  | UpdateLocationAction
  | UpdateLocationListAction
  | SetViewType
  | SetFiltersAction
  | SetSortAction
  | SetOrdersAction
  | UpdateOrderAction
  | OpenSidebarAction
  | CloseSidebarAction
  | CloseModalAction
  | SetUserAction
  | LoadUserPrefsAction
  | SetAutoClearTimeouts
  | SetOrdersPerPage
  | SetFontSize
  | SetColumns
  | SetCustomerViewType
  | AddPinnedOrder
  | RemovePinnedOrder;
