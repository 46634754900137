import React from "react";
import { Row } from "react-table";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cellProps = (props: any, { cell }: any) =>
  getStyles(props, cell.column.align);

const getStyles = (props: unknown, align = "left") => {
  let justifyContent = "flex-start";

  switch (align) {
    case "right":
      justifyContent = "flex-end";
      break;
    case "center":
      justifyContent = "center";
      break;
  }
  return [
    props,
    {
      style: {
        justifyContent,
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];
};

export interface TableRowProps<T extends object> {
  row: Row<T>;
}

export function TableRow<T extends object>(props: TableRowProps<T>) {
  const { row } = props;

  return (
    <div {...row.getRowProps()} data-cy={`row-${row.id}`}>
      {row.cells.map((cell) => {
        return (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <div {...cell.getCellProps(cellProps as any)} className="td">
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
}
