import moment from "moment";
import React from "react";
import styled from "styled-components";
import { OrderStatus, OrderTimestamps } from "../../../models/order";
import { ViewType } from "../../../models/view";
import { Box } from "../../base/box/Box";
import { TimerTag } from "../../base/tag/TimerTag";
import { useDelayedStatus } from "../../hooks/useDelayedStatus";

export interface StatusProps {
  viewType: ViewType;
  status: OrderStatus;
  timestamps?: OrderTimestamps;
  statusToDisplayName: (status: OrderStatus) => string;
}

export function Status({
  viewType,
  status,
  timestamps,
  statusToDisplayName,
  ...props
}: StatusProps) {
  const { delayedStatus } = useDelayedStatus({
    viewType,
    status,
    timestamps,
  });

  if (!delayedStatus) {
    return null;
  }

  const shouldShowTimerTag =
    viewType === ViewType.RESTAURANT &&
    delayedStatus === OrderStatus.CheckIn &&
    timestamps?.checkIn;

  return (
    <StyledStatus status={delayedStatus} position="relative" {...props}>
      {statusToDisplayName(delayedStatus)}
      {shouldShowTimerTag && (
        <Box
          as="span"
          display="flex"
          position="absolute"
          width="100%"
          height="100%"
          justifyContent="end"
          alignItems="center"
          top={0}
          bottom={0}
          margin="auto"
          padding="1rem"
        >
          <TimerTag
            defaultTimer={moment().diff(timestamps?.checkIn, "seconds") * 1000}
            timerWarningInMinutes={10}
          />
        </Box>
      )}
    </StyledStatus>
  );
}

const StyledStatus = styled(Box)<{ status: OrderStatus }>`
  background-color: ${(props) => props?.theme?.colors?.statuses[props.status]};
  border-radius: 0.625rem;
  text-align: center;
  overflow: hidden;
  width: 100%;
  max-width: 30rem;
  padding: 1rem;
  align-self: center;
`;
