import React from "react";
import { logAmplitudeEvent } from "../../analytics/amplitude";
import { LogEvent } from "../../analytics/types";
import { SET_ORDERS_PER_TABLE } from "../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../reducers/reducers";
import { IncrementInput } from "../increment-input/IncrementInput";

export function OrdersPerTableSetting() {
  const dispatch = useRadarDispatch();
  const {
    settings: { customerViewOrdersPerTable = 10 },
  } = useRadarState();

  function onDecrement() {
    if (customerViewOrdersPerTable > 5) {
      dispatch({
        type: SET_ORDERS_PER_TABLE,
        numberOfOrders: customerViewOrdersPerTable - 1,
      });
      logAmplitudeEvent(LogEvent.UpdateOrdersPerTable, {
        TYPE: "DECREMENT",
        NUMBER_OF_ORDERS: customerViewOrdersPerTable - 1,
      });
    }
  }
  function onIncrement() {
    if (customerViewOrdersPerTable < 30) {
      dispatch({
        type: SET_ORDERS_PER_TABLE,
        numberOfOrders: customerViewOrdersPerTable + 1,
      });
      logAmplitudeEvent(LogEvent.UpdateOrdersPerTable, {
        TYPE: "INCREMENT",
        NUMBER_OF_ORDERS: customerViewOrdersPerTable + 1,
      });
    }
  }

  return (
    <IncrementInput
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      text={String(customerViewOrdersPerTable)}
    />
  );
}
