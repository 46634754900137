import { OrderStatus } from "../../models/order";

export function mapStatusToDisplayNameRestaurantView(
  status: OrderStatus
): string {
  switch (status) {
    case OrderStatus.Submit:
      return "Order placed";
    case OrderStatus.CheckIn:
      return "Checked in";
    case OrderStatus.Ready:
      return "Bumped";
    case OrderStatus.Complete:
      return "Completed";
    case OrderStatus.Error:
      return "Order Error";
    default:
      return "";
  }
}

export function mapStatusToDisplayNameCustomerView(
  status: OrderStatus
): string {
  switch (status) {
    case OrderStatus.Submit:
      return "Order submitted";
    case OrderStatus.CheckIn:
      return "Preparing order";
    case OrderStatus.Ready:
      return "Ready";
    case OrderStatus.Complete:
      return "Completed";
    case OrderStatus.Error:
      return "Order Error";
    default:
      return "";
  }
}

export function mapStatusToDisplayNameCustomerSidebarFilter(
  status: OrderStatus
): string {
  switch (status) {
    case OrderStatus.Submit:
      return "Submitted";
    case OrderStatus.CheckIn:
      return "Preparing";
    case OrderStatus.Ready:
      return "Ready";
    case OrderStatus.Complete:
      return "Completed";
    case OrderStatus.Error:
      return "Order Error";
    default:
      return "";
  }
}
