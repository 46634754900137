import { Order, OrderStatus } from "../../models/order";

import moment from "moment";
import { AutoClearTimeouts, FilterConfig } from "../../reducers/state";

// Function that takes an order and returns true if the order passes a filter
export type FilterFn = (order: Order) => boolean;

export function filterOrdersByConfig(
  orders: Order[],
  filterConfig: FilterConfig
): Order[] {
  if (orders == null) {
    return [];
  }

  let filteredOrders = orders;
  if (filterConfig) {
    filteredOrders = filterByCategory(
      orders,
      (order) => order.fulfillment.destination,
      filterConfig.DESTINATION
    );
    filteredOrders = filterByCategory(
      filteredOrders,
      (order) => order.status,
      filterConfig.STATUS
    );
  }

  return filteredOrders;
}

function filterByCategory(
  orders: Order[],
  fieldFetchFn: (order: Order) => string,
  items?: string[]
): Order[] {
  if (items == null || items.length === 0) {
    return orders;
  }
  return orders.filter((order) => items.includes(fieldFetchFn(order)));
}

export function filterByTimeInStatus(
  minutesToWait: AutoClearTimeouts
): FilterFn {
  return function filter(order: Order) {
    if (
      order.status === OrderStatus.CheckIn &&
      moment(order.timestamps.checkIn).isBefore(
        moment().subtract(minutesToWait[OrderStatus.CheckIn], "m")
      )
    ) {
      return false;
    }
    if (
      order.status === OrderStatus.Ready &&
      moment(order.timestamps.ready).isBefore(
        moment().subtract(minutesToWait[OrderStatus.Ready], "m")
      )
    ) {
      return false;
    }
    if (
      order.status === OrderStatus.Error &&
      moment(order.timestamps.error).isBefore(
        moment().subtract(minutesToWait[OrderStatus.Error], "m")
      )
    ) {
      return false;
    }
    return true;
  };
}

export function filterOutStatus(status: OrderStatus): FilterFn {
  return function filter(order: Order) {
    return order.status !== status;
  };
}

export function filterCompletedOlderThanToday(order: Order): boolean {
  if (order.status !== OrderStatus.Complete) {
    return true;
  }
  const today = moment();
  return moment(order.timestamps.complete).dayOfYear() === today.dayOfYear();
}
