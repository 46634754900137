import React, { useMemo } from "react";
import { Column, Row, useFlexLayout, useTable } from "react-table";
import styled from "styled-components";
import { TableRow } from "./table-row";

interface StyledTableProps {
  readonly applyStickyHeader: boolean;
}

const StyledTable = styled.div<StyledTableProps>`
  padding: 1rem;
  display: block;

  .table {
    border-spacing: 0;

    .thead {
      ${(props) =>
        props.applyStickyHeader && "position: sticky; top: 0; z-index: 1;"}
      color: ${(props) => props?.theme?.colors?.secondary};
      background-color: ${(props) => props?.theme?.colors?.tertiary};

      .tr {
        border: none;
      }
    }

    .tbody {
      color: ${(props) => props?.theme?.colors?.text};

      .tr {
        align-items: center;
      }
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      :nth-child(even) {
        background-color: #f4f8f9;
      }

      border-bottom: 1px solid rgba(199, 199, 205, 0.5);
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        background: blue;
        width: 0.625rem;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: red;
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerProps = (props: any, { column }: any) =>
  getStyles(props, column.align);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStyles = (props: any, align = "left") => {
  let justifyContent = "flex-start";

  switch (align) {
    case "right":
      justifyContent = "flex-end";
      break;
    case "center":
      justifyContent = "center";
      break;
  }
  return [
    props,
    {
      style: {
        justifyContent,
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];
};

export interface TableProps<T extends object> {
  data: T[];
  rowIdAccessor: (item: T) => string;
  columns: Column<T>[];
  noContent?: JSX.Element;
  renderRow?: (row: Row<T>) => JSX.Element;
  applyStickyHeader?: boolean;
}

export function Table<T extends object>({
  data,
  columns,
  noContent,
  rowIdAccessor,
  renderRow,
  applyStickyHeader,
}: TableProps<T>) {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(
      {
        columns,
        data,
        defaultColumn,
        getRowId: rowIdAccessor,
      },
      useFlexLayout
    );

  if (rows.length === 0) {
    return noContent || null;
  }

  // Render the UI for your table
  return (
    <StyledTable applyStickyHeader={!!applyStickyHeader}>
      <div {...getTableProps()} className="table">
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="tbody">
          {rows.map((row) => {
            prepareRow(row);

            return (
              <div className="tr" key={row.id}>
                {renderRow ? renderRow(row) : <TableRow row={row} />}
              </div>
            );
          })}
        </div>
      </div>
    </StyledTable>
  );
}
