import styled from "styled-components";
import { Box } from "../box/Box";

export const FluidGrid = styled(Box)`
  width: 100%;
  display: grid;
  align-items: start;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  grid-gap: 0.75rem;
`;
