import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LogEvent } from "../../../analytics/types";
import { usePageViewAnalytic } from "../../../analytics/usePageViewAnalytic";
import { getEnvConfig } from "../../../lib/config/config";
import { ViewType } from "../../../models/view";
import { SET_VIEW_TYPE } from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";
import { RESTAURANT_ORDERS_PATH } from "../../page-routing-config";
import { OrdersViewTemplate } from "../orders-view-template";
import { CustomerViewContent } from "./customer-view-content";
import { CustomerViewFooter } from "./customer-view-footer";
import { CustomerViewHeader } from "./customer-view-header";

export function CustomerView() {
  const { selectedLocation, user, filters, settings } = useRadarState();
  const dispatch = useRadarDispatch();
  const allowedCustomerFacingViewLocations =
    getEnvConfig().Locations.allowedCustomerFacingView;
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: SET_VIEW_TYPE, viewType: ViewType.CUSTOMER });
  }, []);

  usePageViewAnalytic(LogEvent.View, {
    viewType: ViewType.CUSTOMER,
    locationName: selectedLocation?.name,
    locationNumber: selectedLocation?.locationNumber,
    destinationFilters: filters?.customer?.DESTINATION,
    statusFilters: filters?.customer?.STATUS,
    sortType: settings?.sortType,
    bumpTimeout: settings?.autoClearTimeouts?.Ready,
  });

  if (
    !selectedLocation ||
    !allowedCustomerFacingViewLocations.includes(
      selectedLocation.locationNumber
    )
  ) {
    if (user) {
      history.replace(RESTAURANT_ORDERS_PATH);
    }

    return null;
  }

  return (
    <OrdersViewTemplate
      headerContent={<CustomerViewHeader />}
      bodyContent={<CustomerViewContent />}
      footerContent={<CustomerViewFooter />}
    />
  );
}
