import React from "react";
import styled from "styled-components";
import { border, BorderProps } from "styled-system";

interface Props {
  onDecrement: () => void;
  onIncrement: () => void;
  text: string | number;
}

export const IncrementInput = ({ onDecrement, onIncrement, text }: Props) => {
  return (
    <StyledBumpTimeoutPlusMinusInput>
      <PlusMinusContainer>
        <StyledButton borderRight="solid 1px #f7f7f7" onClick={onDecrement}>
          -
        </StyledButton>
        <StyledText>{text}</StyledText>
        <StyledButton borderLeft="solid 1px #f7f7f7" onClick={onIncrement}>
          +
        </StyledButton>
      </PlusMinusContainer>
    </StyledBumpTimeoutPlusMinusInput>
  );
};

const StyledBumpTimeoutPlusMinusInput = styled.div`
  width: 15rem;
`;

const PlusMinusContainer = styled.div`
  display: flex;
  border-radius: 1.625rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  height: 3.25rem;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled.button<BorderProps>`
  border: none;
  cursor: pointer;
  width: 30%;
  height: 100%;
  background-color: transparent;
  padding: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme?.colors?.primary};
  ${border};
`;

const StyledText = styled.span`
  font-size: 1rem;
  color: ${(props) => props.theme?.colors?.primary};
  font-weight: bold;
`;
