import { ChickenSpinner } from "@cfacorp/cowponents";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Box } from "../../../components/base/box/Box";
import { PagingIndicator } from "../../../components/base/paging-indicator/paging-indicator";
import { MenuSidebar } from "../../../components/menu-sidebar/menu-sidebar";
import { OrderGrid } from "../../../components/order-grid/order-grid";
import { OrderGridLegend } from "../../../components/order-grid/order-grid-legend";
import { OrdersTable } from "../../../components/orders-table/orders-table";
import { SettingsSidebar } from "../../../components/settings-sidebar/settings-sidebar";
import { useOrderAutoPager } from "../../../hooks/useOrderAutoPager";
import { FilterCategory } from "../../../models/filter";
import { Order, OrderStatus } from "../../../models/order";
import { SidebarType } from "../../../models/sidebar";
import { SortType } from "../../../models/sort";
import { ViewType } from "../../../models/view";
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "../../../reducers/actions";
import { useRadarDispatch, useRadarState } from "../../../reducers/reducers";
import { CustomerViewType } from "../../../reducers/state";
import {
  filterByTimeInStatus,
  filterOrdersByConfig,
  filterOutStatus,
} from "../../../utils/filtering/order-filter";
import { sortOrdersByRelevantTimestamp } from "../../../utils/sorting/order-sorter";
import { customerViewColumns } from "./constants";

export function CustomerViewContent() {
  const theme = useTheme();
  const dispatch = useRadarDispatch();
  const { filters, orders, sidebar, settings } = useRadarState();
  const [filteredSortedOrders, setFilteredSortedOrders] = useState<Order[]>([]);
  const ordersPerTable = settings.customerViewOrdersPerTable || 10;
  const tableCount = settings.customerViewTableCount || 1;
  const customerViewType = settings.customerViewType || CustomerViewType.COLUMN;
  const {
    orders: filteredSortedPagedOrders,
    totalPageCount,
    activePageNum,
  } = useOrderAutoPager(
    filteredSortedOrders,
    customerViewType === CustomerViewType.COLUMN
      ? ordersPerTable * tableCount
      : 50,
    settings.customerViewPagingIntervalInMS
  );

  useEffect(() => {
    setFilteredSortedOrders(
      sortOrdersByRelevantTimestamp(
        filterOrdersByConfig(orders.orders, filters[ViewType.CUSTOMER])
          .filter(filterOutStatus(OrderStatus.Complete))
          .filter(filterOutStatus(OrderStatus.Cancelled))
          .filter(filterByTimeInStatus(settings.autoClearTimeouts)),
        settings.sortType === SortType.OLDEST_AT_TOP
      )
    );
  }, [filters, orders.orders, settings.autoClearTimeouts, settings.sortType]);

  if (orders.error) {
    console.error(orders.error);
    return <div>Error {orders.error.toString()}</div>;
  }
  if (orders.loading || !orders.orders) {
    return <StyledChickenSpinner />;
  }

  const orderColumnOne = filteredSortedPagedOrders.slice(0, ordersPerTable);
  const orderColumnTwo = filteredSortedPagedOrders.slice(ordersPerTable);

  return (
    <Box paddingBottom="5rem">
      <MenuSidebar
        viewType={ViewType.CUSTOMER}
        visible={sidebar.isOpen && sidebar.type === SidebarType.MENU}
        categories={[FilterCategory.DESTINATION, FilterCategory.STATUS]}
        onCloseClicked={() => {
          dispatch({ type: CLOSE_SIDEBAR });
        }}
      />
      <SettingsSidebar
        viewType={ViewType.CUSTOMER}
        visible={sidebar.isOpen && sidebar.type === SidebarType.SETTINGS}
        onBackClicked={() => {
          dispatch({ type: OPEN_SIDEBAR, sidebarType: SidebarType.MENU });
        }}
      />

      <Box
        display="flex"
        fontSize={`calc(1rem * ${settings.customerViewFontSize})`}
      >
        {customerViewType === CustomerViewType.COLUMN ? (
          <>
            <OrdersTable
              orders={
                tableCount === 2 ? orderColumnOne : filteredSortedPagedOrders
              }
              columns={customerViewColumns}
            />
            {tableCount === 2 && orderColumnTwo.length > 0 && (
              <OrdersTable
                orders={orderColumnTwo}
                columns={customerViewColumns}
              />
            )}
          </>
        ) : (
          <Box width="100%" paddingY="1.5rem" paddingX="1rem">
            <OrderGridLegend
              items={[
                { color: theme.colors.statuses.Submit, text: "Submitted" },
                { color: theme.colors.statuses.CheckIn, text: "Preparing" },
                { color: theme.colors.statuses.Ready, text: "Ready" },
              ]}
            />
            <OrderGrid orders={filteredSortedPagedOrders} />
          </Box>
        )}
        <PagingContainer>
          <PagingIndicator
            totalPageCount={totalPageCount}
            activePageNum={activePageNum}
          />
        </PagingContainer>
      </Box>
    </Box>
  );
}

const PagingContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0%);
`;

export const StyledChickenSpinner = styled(ChickenSpinner)`
  width: 100%;
  height: 11rem;
  margin-top: 6.25rem;
`;
