import React, { FunctionComponent } from "react";
import { ViewType } from "../../models/view";
import { useRadarState } from "../../reducers/reducers";
import { CustomerViewType } from "../../reducers/state";
import { Box } from "../base/box/Box";
import Sidebar from "../base/sidebar/sidebar";
import { SidebarGroup } from "../base/sidebar/sidebar-group";
import { SidebarText } from "../base/sidebar/sidebar-text";
import { ClearBumpedSetting } from "./ClearBumpedSetting";
import { CustomerViewSetting } from "./CustomerViewSetting";
import { FontSizeSetting } from "./FontSizeSetting";
import { OrdersPerTableSetting } from "./OrdersPerTableSetting";
import { TableCountSetting } from "./TableCountSetting";

type Props = {
  viewType: ViewType;
  visible: boolean;
  onBackClicked: () => void;
};

export const SettingsSidebar: FunctionComponent<Props> = ({
  viewType,
  onBackClicked,
  visible,
}: Props) => {
  const {
    settings: { customerViewType },
  } = useRadarState();

  return (
    <Sidebar
      visible={visible}
      onBack={onBackClicked}
      title="Settings"
      className="settings-sidebar"
    >
      <SidebarGroup>
        <SidebarText>Minutes to clear Bumped orders</SidebarText>
        <Box marginTop="1rem">
          <ClearBumpedSetting />
        </Box>
      </SidebarGroup>
      {viewType === ViewType.CUSTOMER && (
        <>
          <SidebarGroup>
            <SidebarText>View Type</SidebarText>
            <Box marginTop="1rem">
              <CustomerViewSetting />
            </Box>
          </SidebarGroup>
          {customerViewType === CustomerViewType.COLUMN && (
            <>
              <SidebarGroup>
                <SidebarText>Orders per table</SidebarText>
                <Box marginTop="1rem">
                  <OrdersPerTableSetting />
                </Box>
              </SidebarGroup>
              <SidebarGroup>
                <SidebarText>Number of tables</SidebarText>
                <Box marginTop="1rem">
                  <TableCountSetting />
                </Box>
              </SidebarGroup>
            </>
          )}
          <SidebarGroup>
            <SidebarText>Font Size</SidebarText>
            <Box marginTop="1rem">
              <FontSizeSetting />
            </Box>
          </SidebarGroup>
        </>
      )}
    </Sidebar>
  );
};
