import React from "react";
import { CellProps, Column } from "react-table";
import { DeliveryIcon } from "../../../components/orders-table/cell-types/delivery-icon";
import { Status } from "../../../components/orders-table/cell-types/status";
import { DeliveryPartner } from "../../../models/delivery-partners";
import { Order, OrderFulfillmentDestination } from "../../../models/order";
import { ViewType } from "../../../models/view";
import { mapStatusToDisplayNameCustomerView } from "../../../utils/mappers/statusMapper";

export const customerViewColumns: Column<Order>[] = [
  {
    id: "delivery-partner",
    accessor: (order) => order,
    width: 50,
    Cell: ({ value, row: { id } }: CellProps<Order, Order>) => (
      <DeliveryIcon
        data-cy={`vender-${id}`}
        id={
          value.fulfillment.destination ===
          OrderFulfillmentDestination.DOOR_DASH
            ? DeliveryPartner.DOOR_DASH
            : (value?.origin?.id as DeliveryPartner)
        }
      />
    ),
  },
  {
    Header: "Customer",
    accessor: ({ user }: Order): string =>
      `${user.firstName} ${user.lastName}.`,
    Cell: ({ value, row: { id } }: CellProps<Order, string>) => (
      <span style={{ alignSelf: "center" }} data-cy={`customer-${id}`}>
        {value}
      </span>
    ),
  },
  {
    Header: "Order status",
    accessor: (order) => order,
    align: "center",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Cell: ({ value, row: { id } }: CellProps<Order, Order>) => (
      <Status
        data-cy={`status-${id}`}
        viewType={ViewType.CUSTOMER}
        status={value.status}
        timestamps={value.timestamps}
        statusToDisplayName={mapStatusToDisplayNameCustomerView}
      />
    ),
  },
];
