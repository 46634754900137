import React from "react";
import styled from "styled-components";
import { OrderFulfillmentDestination } from "../../../models/order";
import { mapDestinationToDisplayName } from "../../../utils/mappers/destinationMapper";

export interface DestinationProps {
  destination: OrderFulfillmentDestination;
}

export function Destination({ destination, ...props }: DestinationProps) {
  return (
    <StyledDestination {...props}>
      {mapDestinationToDisplayName(destination)}
    </StyledDestination>
  );
}

const StyledDestination = styled.div`
  align-self: center;
`;
