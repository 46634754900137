import { ApolloProvider } from "@apollo/client";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import * as React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { createApolloClient } from "./lib/apollo";
import { oktaAuth } from "./lib/okta/okta";
import { OrdersViewRouter } from "./pages/orders/orders-view-router";
import { ORDERS_PATH, ROOT_PATH } from "./pages/page-routing-config";
import { RadarProvider } from "./reducers/reducers";
import { CFATheme, GlobalStyle } from "./theme";

const client = createApolloClient();

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_: unknown, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <RadarProvider>
        <ApolloProvider client={client}>
          <ThemeProvider theme={CFATheme}>
            <GlobalStyle />
            <Switch>
              <SecureRoute path={ORDERS_PATH} component={OrdersViewRouter} />
              <Route path="/implicit/callback" component={LoginCallback} />
              <SecureRoute path={ROOT_PATH}>
                <Redirect to={ORDERS_PATH} />
              </SecureRoute>
            </Switch>
          </ThemeProvider>
        </ApolloProvider>
      </RadarProvider>
    </Security>
  );
}

export default App;
