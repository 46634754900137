import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";

export interface TagProps extends BoxProps {
  color?: string;
  children?: ReactNode;
}

export function Tag({ color, children, ...props }: TagProps) {
  return (
    <Box
      as="span"
      fontWeight="bold"
      marginX="0.5rem"
      paddingX="0.5rem"
      borderRadius={5}
      color={color}
      {...props}
    >
      {children}
    </Box>
  );
}
