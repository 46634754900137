import React, { useLayoutEffect } from "react";

import { useOrderFetch } from "../../actions/order-fetch";
import { Box } from "../../components/base/box/Box";
import { Footer } from "../../components/base/footer/footer";
import { Header } from "../../components/base/header/header";
import { CLOSE_MODAL, CLOSE_SIDEBAR } from "../../reducers/actions";
import { useRadarDispatch } from "../../reducers/reducers";

export interface OrdersViewTemplateProps {
  headerContent: JSX.Element;
  bodyContent: JSX.Element;
  footerContent?: JSX.Element;
}

export function OrdersViewTemplate({
  headerContent,
  bodyContent,
  footerContent,
}: OrdersViewTemplateProps) {
  const dispatch = useRadarDispatch();
  useOrderFetch();

  useLayoutEffect(() => {
    // When you first render, make sure everything is closed
    dispatch({ type: CLOSE_SIDEBAR });
    dispatch({ type: CLOSE_MODAL });
  }, [dispatch]);

  return (
    <>
      <Header>{headerContent}</Header>
      <Box as="main">{bodyContent}</Box>
      <Footer>{footerContent && footerContent}</Footer>
    </>
  );
}
