import { useEffect, useState } from "react";
import { Order } from "../models/order";
import { getPage } from "../utils/paging/paging-utils";

export interface OrderAutoPagerResponse {
  orders: Order[];
  totalPageCount: number;
  activePageNum: number;
}

export const useOrderAutoPager = (
  orders: Order[],
  pageSize: number,
  pagingInterval: number
): OrderAutoPagerResponse => {
  const [activePageNum, setActivePageNum] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [tick, setTick] = useState("");

  // Set up a ticking clock
  useEffect(() => {
    const interval = setInterval(() => {
      setTick(new Date().toISOString());
    }, pagingInterval);

    return () => {
      clearInterval(interval);
    };
  }, [pagingInterval]);

  // Everytime the timer ticks, advance to the next page
  useEffect(() => {
    setActivePageNum((pageNum) => {
      let nextPage = pageNum + 1;
      if (nextPage >= totalPageCount) {
        nextPage = 0;
      }
      return nextPage;
    });
  }, [tick]);

  // Everytime the filters or the orders change, get the new list of filtered items and set them
  useEffect(() => {
    setTotalPageCount(Math.ceil(orders.length / pageSize));
  }, [orders, pageSize]);

  const finalOrderList = getPage(orders, activePageNum, pageSize);

  return { orders: finalOrderList, totalPageCount, activePageNum };
};
