import styled from "styled-components";

export const SidebarButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background: none;
  padding: 0px;
  height: 100%;
  cursor: pointer;
`;
